<template lang="html">
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="SYN001---DSKTP---Otevřené-smlouvy" transform="translate(-713.000000, -342.000000)">
        <rect fill="#FFFFFF" x="0" y="0" width="1200" height="919"></rect>
        <g id="Group-5" transform="translate(377.000000, 270.000000)" stroke="#D4DFF0">
          <g id="ico/ico-smlouva-ne-potvrzena" transform="translate(336.000000, 72.000000)">
            <g id="Group-4">
              <circle id="Oval-Copy" stroke-width="2" fill-rule="nonzero" cx="9" cy="9" r="8"></circle>
              <g id="Group-2" transform="translate(5.800000, 5.800000)" fill-rule="nonzero" stroke-width="2">
                <path
                  d="M0.465826134,0.306883548 L6.33788904,6.17894645"
                  id="Path-22-Copy"
                  transform="translate(3.401858, 3.242915) scale(-1, 1) translate(-3.401858, -3.242915) "
                ></path>
                <path d="M0.465826134,0.306883548 L6.33788904,6.17894645" id="Path-22-Copy-2"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoCirleCross',
}
</script>
