import axios from 'axios'
import { environment } from '@/config/environment'
import { authHeader, generateAuthHeader } from '@/config/authHeader'
import { buildQuery } from '@/common/reusable/query'

export const signWithBankIdSign = ({ contractId, payload, authToken = null }) => {
  return axios
    .get(`${environment.getApiUrl()}/api/v2/contract/${contractId}/sign/bankId/session?${buildQuery(payload)}`, {
      headers: null === authToken ? authHeader() : generateAuthHeader({ authorization: 'ApiToken', authToken }),
    })
    .then((resp) => {
      window.open(resp.data?.redirectUrl, '_self')
    })
    .catch((err) => {
      throw err.response.data
    })
}

export const signWithHandySignature = ({ docsId, payload, authToken = null }) => {
  return axios
    .post(`${environment.getApiUrl()}/api/v1/docs/${docsId}/sign/handySignatur/session`, payload, {
      headers: null === authToken ? authHeader() : generateAuthHeader({ authorization: 'ApiToken', authToken }),
    })
    .then((resp) => {
      window.open(resp.data?.signatureUrl, '_self')
    })
    .catch((err) => {
      throw err.response.data
    })
}
