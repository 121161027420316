import { authorizedApiRequest } from '@/services/ApiService'

/**
 * Method to fetch bank id auth data object
 *
 * @param idAuth
 * @param idAuthBankId
 * @returns {Promise<*>}
 */
export const fetchBankId = ({ idAuth, idAuthBankId }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v1/auths/${idAuth}/bankId/${idAuthBankId}`,
  })
}

/**
 * Method to fetch bank id auth user data
 *
 * @param idAuth
 * @param idAuthBankId
 * @returns {Promise<*>}
 */
export const fetchBankIdUser = ({ idAuth, idAuthBankId }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v1/auths/${idAuth}/bankId/${idAuthBankId}/user`,
  })
}

export const fetchBankIdAddressList = ({ idAuth, idAuthBankId, idBankIdUser }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v1/auths/${idAuth}/bankId/${idAuthBankId}/user/${idBankIdUser}/address/list`,
  })
}

export const fetchBankIdDocumentList = ({ idAuth, idAuthBankId, idBankIdUser }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v1/auths/${idAuth}/bankId/${idAuthBankId}/user/${idBankIdUser}/document/list`,
  })
}

/**
 * Method to approve authentication
 *
 * @param idAuth
 * @param idAuthBankId
 * @returns {Promise<unknown>}
 */
export const approveBankIdAuth = ({ idAuth, idAuthBankId }) => {
  return authorizedApiRequest({
    method: 'PATCH',
    endpoint: `/api/v1/auths/${idAuth}/bankId/${idAuthBankId}/approve`,
  })
}

/**
 * Method to reject authentication
 *
 * @param idContract
 * @param idDocsPerson
 * @param data
 * @returns {Promise<unknown>}
 */
export const rejectBankIdAuth = ({ idContract, idDocsPerson, data }) => {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: `/api/v1/auths/contracts/${idContract}/person/${idDocsPerson}/reject`,
    data,
  })
}
