<template>
  <v-row align="center" justify="space-between" no-gutters>
    <v-col cols="auto">
      <DocumentPersonsStatus :sign-identities="signIdentities" :is-proposer="isProposer" />
    </v-col>

    <v-col cols="auto">
      <DocumentHistory :history="history" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { fetchContractDocumentHistory } from '@contract/services/contractService'
import { randomDarkColor } from '@/common/reusable/colorFunctions'
import DocumentPersonsStatus from '@contract/components/DocumentPersonsStatus'
import DocumentHistory from '@contract/components/DocumentHistory'

export default {
  name: 'ContractDocumentTabInfo',
  components: {
    DocumentHistory,
    DocumentPersonsStatus,
  },
  props: {
    contractId: {
      type: [Number, String],
      required: true,
    },
    currentSignIdentity: {
      type: Object,
      default: null,
    },
    signIdentities: {
      type: Array,
      default: () => {
        return []
      },
    },
    usePublicFetch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      privateHistory: [],
    }
  },
  computed: {
    ...mapGetters({
      publicSignHistory: 'publicContract/history',
    }),
    isProposer() {
      return this.currentSignIdentity?.is_proposer || false
    },
    history() {
      if (this.usePublicFetch) {
        return this.publicSignHistory
      }

      return this.privateHistory
    },
  },
  async mounted() {
    let identities = {}

    if (this.usePublicFetch) {
      await this.fetchDocumentHistory({
        authToken: this.$route.params.hash,
        idContract: this.contractId,
        idCurrentSignIdentity: this.currentSignIdentity?.id || null,
      })
    } else {
      this.privateHistory = await fetchContractDocumentHistory({
        idContract: this.contractId,
        idCurrentSignIdentity: this.currentSignIdentity?.id || null,
      })
    }

    this.signIdentities.forEach((identity) => {
      if ('undefined' === typeof identities[identity.email]) {
        const customColor = randomDarkColor()
        identities[identity.email] = customColor
        identity.color = customColor
      }
    })

    this.history.forEach((e) => {
      e.color = identities[e.docs_person?.email || e.logged_user.email]
    })
  },
  methods: {
    ...mapActions({
      fetchDocumentHistory: 'publicContract/fetchHistory',
    }),
  },
}
</script>

<style scoped></style>
