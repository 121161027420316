<template>
  <div
    v-click-outside="onClickOutside"
    class="comments-point"
    :style="`top: ${coordinates.top}%; left: ${coordinates.left}%`"
  >
    <v-btn
      fab
      x-small
      color="#8141c7"
      :class="{ 'comments-point__resolved': isResolved }"
      @click="openDialog = !openDialog"
      dark
    >
      <v-icon v-if="isResolved">mdi-check</v-icon>
      <template v-else>
        {{ comment.anchor.title }}
      </template>
    </v-btn>

    <template v-if="openDialog">
      <v-dialog v-if="$vuetify.breakpoint.mobile" v-model="openDialog" persistent :retain-focus="false">
        <v-card>
          <v-system-bar color="#ffffff" class="pt-3">
            <v-spacer></v-spacer>
            <v-icon @click="closeCommentDialog()" medium>mdi-close</v-icon>
          </v-system-bar>

          <template v-if="isEditing || !isCreated">
            <v-card-title class="pa-5 pt-0 pb-3">
              {{ $t('review.comments.content') }}
            </v-card-title>

            <v-card-text class="pa-5 pt-0 pb-3">
              <v-textarea ref="commentTextarea" v-model="commentMessage" :rules="commentRules" outlined></v-textarea>
            </v-card-text>

            <v-card-actions class="pa-5 pt-0">
              <v-spacer></v-spacer>
              <v-btn
                class="pr-5 pl-5"
                color="#692bab"
                @click="isEditing ? updateComment() : createComment()"
                :loading="isSavingComment"
                dark
              >
                {{ isEditing ? $t('review.comments.update_comment') : $t('review.comments.add_comment') }}
              </v-btn>
            </v-card-actions>
          </template>

          <template v-else>
            <v-card-text class="pa-5 pt-0 pb-0">
              <v-switch
                inset
                color="#692bab"
                v-model="isResolved"
                :label="$t('review.comments.resolve')"
                @change="changeCommentsState('toggleResolve', comment)"
              />
            </v-card-text>

            <v-card-text class="pa-2">
              <CommentsMessage
                :comment="comment"
                :can-edit-comment="canEditComment"
                @updateComment="toggleCommentToEditMode($event)"
                @deleteComment="deleteComment($event)"
              />
            </v-card-text>

            <div v-if="comment.replies.length > 0">
              <v-divider></v-divider>

              <!--              TODO - virtual-scroll with dynamic item height-->
              <v-card-text class="pa-2 scrollable-content">
                <h3>{{ $t('review.comments.replies') }}</h3>

                <CommentsMessage
                  v-for="item in this.comment.replies"
                  :key="item.id"
                  :comment="item"
                  :can-edit-comment="canEditComment"
                  @updateComment="toggleCommentToEditMode($event)"
                  @deleteComment="deleteComment($event)"
                />
              </v-card-text>
            </div>

            <div v-if="!isResolved && isEditable">
              <v-divider></v-divider>

              <v-card-text class="pa-5 pb-0">
                <v-textarea ref="commentTextarea" v-model="commentMessage" :rules="commentRules" outlined></v-textarea>
              </v-card-text>

              <v-card-actions class="pa-5 pt-0">
                <v-spacer></v-spacer>
                <v-btn
                  class="pr-5 pl-5"
                  color="#692bab"
                  @click="createComment(comment.id)"
                  :loading="isSavingComment"
                  dark
                >
                  {{ $t('review.comments.add_reply') }}
                </v-btn>
              </v-card-actions>
            </div>
          </template>
        </v-card>
      </v-dialog>

      <v-card
        v-else
        class="comments-point__dialog"
        :class="`comments-point__dialog--${coordinates.left < 50 ? 'right' : 'left'}`"
        min-width="25em"
        elevation="24"
        light
      >
        <v-system-bar color="#ffffff" class="pt-3">
          <v-spacer></v-spacer>
          <v-icon @click="closeCommentDialog()" medium>mdi-close</v-icon>
        </v-system-bar>

        <template v-if="isEditing || !isCreated">
          <v-card-title class="pa-5 pt-0 pb-3">
            {{ $t('review.comments.content') }}
          </v-card-title>

          <v-card-text class="pa-5 pt-0 pb-3">
            <v-textarea
              ref="commentTextarea"
              v-model="commentMessage"
              :rules="commentRules"
              outlined
              autofocus
            ></v-textarea>
          </v-card-text>

          <v-card-actions class="pa-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              class="pr-5 pl-5"
              color="#692bab"
              @click="isEditing ? updateComment() : createComment()"
              :loading="isSavingComment"
              dark
            >
              {{ isEditing ? $t('review.comments.update_comment') : $t('review.comments.add_comment') }}
            </v-btn>
          </v-card-actions>
        </template>

        <template v-else>
          <v-card-text class="pa-5 pt-0 pb-0">
            <v-switch
              inset
              color="#692bab"
              v-model="isResolved"
              :label="$t('review.comments.resolve')"
              @change="changeCommentsState('toggleResolve', comment)"
            />
          </v-card-text>

          <v-card-text class="pa-2">
            <CommentsMessage
              :comment="comment"
              :can-edit-comment="canEditComment"
              @updateComment="toggleCommentToEditMode($event)"
              @deleteComment="deleteComment($event)"
            />
          </v-card-text>

          <div v-if="comment.replies.length > 0">
            <v-divider></v-divider>

            <!--            TODO - virtual-scroll with dynamic item height-->
            <v-card-text class="pa-2 scrollable-content">
              <h3>{{ $t('review.comments.replies') }}</h3>

              <CommentsMessage
                v-for="item in this.comment.replies"
                :key="item.id"
                :comment="item"
                :can-edit-comment="canEditComment"
                @updateComment="toggleCommentToEditMode($event)"
                @deleteComment="deleteComment($event)"
              />
            </v-card-text>
          </div>

          <div v-if="!isResolved && isEditable">
            <v-divider></v-divider>

            <v-card-text class="pa-5 pb-0">
              <v-textarea
                ref="commentTextarea"
                v-model="commentMessage"
                :rules="commentRules"
                outlined
                autofocus
              ></v-textarea>
            </v-card-text>

            <v-card-actions class="pa-5 pt-0">
              <v-spacer></v-spacer>
              <v-btn
                class="pr-5 pl-5"
                color="#692bab"
                @click="createComment(comment.id)"
                :loading="isSavingComment"
                dark
              >
                {{ $t('review.comments.add_reply') }}
              </v-btn>
            </v-card-actions>
          </div>
        </template>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ContractService } from '@/services/ContractService.js'
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker'

export default {
  name: 'CommentsPoint',
  components: {
    CommentsMessage: () => import('@/views/dashboard/components/CommentsMessage'),
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    contractId: {
      type: [String, Number],
      required: true,
    },
    canEditComment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openDialog: false,
      isResolved: false,
      isToEdit: false,
      savingComment: false,
      commentToEdit: null,
      commentMessage: '',
      commentRules: [
        (value) => !!value || this.$t('review.comments.validation.required'),
        (value) => (value || '').length <= 1000 || this.$t('review.comments.validation.max_chars'),
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile',
    }),
    coordinates() {
      return {
        top: this.comment.anchor.offset_top,
        left: this.comment.anchor.offset_left,
      }
    },
    isCreated() {
      return typeof this.comment.created_at !== 'undefined'
    },
    isEditable() {
      return (hasFeatureFlag(this.comment, 'isEditable') && this.canEditComment) || false
    },
    isEditing() {
      return this.isToEdit
    },
    isSavingComment() {
      return this.savingComment
    },
  },
  created() {
    if (this.comment.openDialog) {
      this.openDialog = true
    }
  },
  mounted() {
    this.isResolved = hasFeatureFlag(this.comment, 'isResolved') || false
  },
  methods: {
    onClickOutside() {
      if (this.$vuetify.breakpoint.mobile || this.isSavingComment) {
        return
      }

      this.openDialog = false
    },
    changeCommentsState(action, data) {
      this.clearState()
      this.$emit('disable-comments-creating', true)
      this.savingComment = true

      ContractService.manageComments(this.contractId, action, data)
        .then(() => {
          switch (action) {
            case 'create':
              this.openDialog = false
              break
            case 'edit':
              this.isToEdit = false
              break
          }

          this.$emit('reload-comments')
          this.$emit('disable-comments-creating', false)
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'))
        })
        .finally(() => {
          this.savingComment = false
        })
    },
    createComment(parentId = null) {
      if (!this.$refs.commentTextarea.validate()) {
        return
      }

      const commentsData = {
        ...this.comment,
        anchor: {
          ...this.comment.anchor,
          userId: this.user.id,
          userAvatarUrl: this.user.avatar_url || false,
        },
        content: this.commentMessage,
        parent_comment_id: parentId,
      }

      this.changeCommentsState('create', commentsData)
    },
    updateComment() {
      if (!this.$refs.commentTextarea.validate()) {
        return
      }

      const commentsData = {
        ...this.commentToEdit,
        content: this.commentMessage,
      }

      this.changeCommentsState('edit', commentsData)
    },
    deleteComment(comment) {
      this.changeCommentsState('delete', comment)
    },
    toggleCommentToEditMode(comment) {
      this.commentToEdit = comment
      this.commentMessage = comment.content
      this.isToEdit = true
    },
    closeCommentDialog() {
      if (this.isToEdit) {
        this.clearState()

        return
      }

      this.openDialog = false

      if (!this.isCreated) {
        this.$emit('destroy-uncreated-comment')
      }
    },
    clearState() {
      this.isToEdit = false
      this.commentToEdit = null
      this.commentMessage = ''
      this.$refs?.commentTextarea?.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.comments-point {
  cursor: default;
  position: absolute;

  &__dialog {
    z-index: 1;
    position: absolute;
    border-radius: 8px;

    &--left {
      top: 0;
      right: 3rem;

      &::after {
        content: '';
        position: absolute;
        top: 0.5rem;
        right: -0.5rem;
        background-color: #ffffff;
        transform: rotate(-45deg);
        height: 1rem;
        width: 1rem;
      }
    }

    &--right {
      top: 0;
      left: 3rem;

      &::after {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: -0.5rem;
        background-color: #ffffff;
        transform: rotate(-45deg);
        height: 1rem;
        width: 1rem;
      }
    }
  }

  &__resolved {
    opacity: 0.6;
  }
}

.scrollable-content {
  max-height: 20rem;
  overflow: auto;
}
</style>
