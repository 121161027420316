/**
 * Method return position of element
 *
 * @param el
 * @returns {number}
 */
export const getSignaturePosition = (el) => {
  const position = {
    vertical: 0,
    horizontal: 0,
  }

  while (el) {
    position.vertical += el.offsetTop - el.scrollTop + el.clientTop
    position.horizontal += el.offsetLeft - el.scrollLeft + el.clientLeft

    el = el.offsetParent
  }

  return position
}

/**
 * Method return vertical position of element
 *
 * @param el
 * @returns {number}
 */
export const getSignatureVerticalPosition = (el) => {
  return getSignaturePosition(el).vertical
}

/**
 * Method return horizontal position of element
 *
 * @param el
 * @returns {number}
 */
export const getSignatureHorizontalPosition = (el) => {
  return getSignaturePosition(el).horizontal
}
