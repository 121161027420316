<script setup>
import { onMounted, ref } from 'vue';
import { PDFDocument } from 'pdf-lib'
import pdf from '@digitalfactorycz/pdf-viewer'
import DocumentPageLoader from '@/common/skeletons/DocumentPageLoader';

const props = defineProps({
  attachmentLink: { type: String, required: true },
  attachmentType: { type: String, required: true },
})

const pages = ref([])

onMounted(() => {
  if(props.attachmentType === 'application/pdf') {
    fetch(props.attachmentLink)
      .then(async (resp) => {
        const documentBuffer = await resp.arrayBuffer()
        const pdfDoc = await PDFDocument.load(documentBuffer, {
          updateMetadata: false,
          renderInteractiveForms: false,
          ignoreEncryption: true,
        })

        pages.value = pdfDoc.getPages()
      })
  }
})
</script>

<template>
  <div class="mt-5">
    <div v-if="attachmentType === 'application/pdf'">
      <pdf
        v-for="(page, index) in pages"
        :key="page.id"
        :id="page.id"
        :src="attachmentLink"
        :page="index+1"
      >
        <template slot="loading">
          <DocumentPageLoader />
        </template>
      </pdf>
    </div>

    <v-img v-else class="mx-auto" :src="attachmentLink" max-width="800px"></v-img>
  </div>
</template>

<style></style>