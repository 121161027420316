<template>
  <v-card elevation="0">
    <div v-if="isLoading" style="min-height: 20rem">
      <Loader />
    </div>

    <template v-else-if="error">
      <v-row class="pb-5" align="center" justify="start">
        <v-col cols="auto">{{ $t('general.data_loading_error') }}</v-col>
      </v-row>
    </template>

    <template v-else-if="isInProcess">
      <v-row class="pb-5" align="center" justify="start">
        <v-col cols="auto">
          {{ $t('aml.auth.not_filled') }}
        </v-col>
      </v-row>
    </template>

    <template v-else-if="isRejectedByProposer">
      <v-row class="pb-5" align="center" justify="start">
        <v-col cols="auto">
          {{ $t('verification.rejected') }}
        </v-col>
      </v-row>
    </template>

    <template v-else-if="isSubmittedByCounterparty || isApprovedByProposer">
      <BankIdContentCardBasicInfo :bank-id-data="bankIdData" :id-auth="idAuth" :id-auth-bank-id="idAuthBankId" />

      <v-row v-if="canShowBankIdAddressList" class="pb-5">
        <v-col cols="12" class="title font-weight-bold pb-0"> {{ $t('general.addresses') }}: </v-col>

        <v-col v-for="item in bankIdAddressList" :key="item.id" cols="12">
          <BankIdContentCardAddressItem :bank-id-address="item" :id-auth="idAuth" :id-auth-bank-id="idAuthBankId" />
        </v-col>
      </v-row>

      <v-row v-if="canShowBankIdDocumentList" class="pb-5">
        <v-col cols="12" class="title font-weight-bold pb-0"> {{ $t('docs') }}: </v-col>

        <v-col v-for="item in bankIdDocumentList" :key="item.id" cols="12">
          <BankIdContentCardDocumentItem :bank-id-document="item" :id-auth="idAuth" :id-auth-bank-id="idAuthBankId" />
        </v-col>
      </v-row>
    </template>

    <v-card-actions v-if="canShowActionsWithAuth" class="pt-5 px-0">
      <v-row align="center" justify="center">
        <v-col class="col-12 col-md-auto">
          <v-btn
            color="primary"
            x-large
            min-width="14rem"
            :disabled="!isSubmittedByCounterparty"
            :loading="submitted"
            :block="$vuetify.breakpoint.mobile"
            @click="submit"
          >
            {{ $t('general.approve') }}
          </v-btn>
        </v-col>

        <v-col class="col-12 col-md-auto">
          <v-dialog v-model="customerReturn.returnDialog" max-width="600px" scrollable persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                x-large
                min-width="14rem"
                :disabled="!isSubmittedByCounterparty"
                :block="$vuetify.breakpoint.mobile"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('general.return_to_customer') }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('general.bug_report') }}</span>
              </v-card-title>

              <v-card-text class="pb-0">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :label="$t('contract.signers')"
                        :value="`${authData.contact.firstname} ${authData.contact.lastname}`"
                        hide-details
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0">
                      <span class="subtitle-2">
                        {{ $t('general.about') }}
                      </span>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        name="customer-return-message"
                        v-model="customerReturn.message"
                        :label="$t('general.yours_message')"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" x-large text @click="customerReturn.returnDialog = false">
                  {{ $t('general.cancel') }}
                </v-btn>
                <v-btn color="primary" x-large text @click="returnToCustomer">
                  {{ $t('general.send') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import {
  approveBankIdAuth,
  fetchBankIdAddressList,
  fetchBankIdDocumentList,
  fetchBankIdUser,
  rejectBankIdAuth,
} from '@contract/services/authsBankIdService'
import { getErrorMessage } from '@/common/reusable/errorResponse.js'
import Loader from '@/components/Loader'
import BankIdContentCardBasicInfo from '@contract/components/BankIdContentCardBasicInfo'
import BankIdContentCardAddressItem from '@contract/components/BankIdContentCardAddressItem'
import BankIdContentCardDocumentItem from '@contract/components/BankIdContentCardDocumentItem'

export default {
  name: 'BankIdContentCard',
  components: {
    BankIdContentCardDocumentItem,
    BankIdContentCardAddressItem,
    BankIdContentCardBasicInfo,
    Loader,
  },
  props: {
    authData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      error: false,
      bankIdData: null,
      bankIdAddressList: [],
      bankIdDocumentList: [],
      submitted: false,
      customerReturn: {
        returnDialog: false,
        message: '',
      },
      rejected: false,
    }
  },
  computed: {
    idAuth() {
      return this.authData?.docs_person_id || null
    },
    idAuthBankId() {
      return this.authData?.auths?.bankId?.id || null
    },
    isLoading() {
      return !this.loaded
    },
    isSubmitting() {
      return this.submitted
    },
    isRejecting() {
      return this.rejected
    },
    isApprovedByProposer() {
      return 'ok' === this.authData?.auths?.bankId?.status || false
    },
    isInProcess() {
      return 'in_process' === this.authData?.auths?.bankId?.status || false
    },
    isRejectedByProposer() {
      return 'rejected' === this.authData?.auths?.bankId?.status || false
    },
    isSubmittedByCounterparty() {
      return 'submitted' === this.authData?.auths?.bankId?.status || false
    },
    canShowBankIdAddressList() {
      return this.bankIdAddressList !== null && this.bankIdAddressList.length > 0
    },
    canShowBankIdDocumentList() {
      return this.bankIdDocumentList !== null && this.bankIdDocumentList.length > 0
    },
    canShowActionsWithAuth() {
      return !this.error && !this.isApprovedByProposer && !this.isRejectedByProposer && !this.isInProcess
    },
  },
  mounted() {
    if ('in_process' === this.authData.auths.bankId.status) {
      return (this.loaded = true)
    }

    fetchBankIdUser({ idAuth: this.idAuth, idAuthBankId: this.idAuthBankId })
      .then(async (resp) => {
        const userAddresses = await fetchBankIdAddressList({
          idAuth: this.idAuth,
          idAuthBankId: this.idAuthBankId,
          idBankIdUser: resp.id,
        })
        const userDocuments = await fetchBankIdDocumentList({
          idAuth: this.idAuth,
          idAuthBankId: this.idAuthBankId,
          idBankIdUser: resp.id,
        })

        this.bankIdAddressList = userAddresses?.addresses || []
        this.bankIdDocumentList = userDocuments?.documents || []
        this.bankIdData = resp
        this.loaded = true
      })
      .catch((err) => {
        this.$notification.error(getErrorMessage({ errorCode: err.errorCode }))
        this.error = true
        this.loaded = true
      })
  },
  methods: {
    ...mapActions({
      fetchAuthState: 'verification/fetchAuthState',
    }),
    async submit() {
      this.submitted = true

      await approveBankIdAuth({
        idAuth: this.idAuth,
        idAuthBankId: this.idAuthBankId,
      })

      this.fetchAuthState(this.$route.params.contract_id).then(() => {
        this.$notification.success(this.$t('aml.auth.approved'))
        this.submitted = false
      })
    },
    returnToCustomer() {
      this.rejected = true

      rejectBankIdAuth({
        idContract: this.$route.params.contract_id,
        idDocsPerson: this.idAuth,
        data: {
          types: ['bankId'],
          message: this.customerReturn.message,
        },
      })
        .then(() => {
          this.customerReturn.returnDialog = false
          this.fetchAuthState(this.$route.params.contract_id).then(() => {
            this.$notification.success(this.$t('aml.auth.denied'))
            this.rejected = false
          })
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'))
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
