<template>
  <v-avatar rounded min-width="7rem" min-height="7rem">
    <div class="image-view" :class="{ active: state !== 'thumbnail' }">
      <div ref="thumbnail" class="thumbnail" @click="enlarge">
        <img :src="thumbnailSrc" class="default" alt="" />
      </div>

      <div class="original" :class="state" :style="styles" @click="reset">
        <img v-if="state === 'enlarged' && originalLoaded" :src="imageSrc" alt="" />
        <img v-else-if="isLoading" :src="thumbnailSrc" alt="" />
        <img v-else :src="thumbnailSrc" alt="" />
      </div>

      <img :src="imageSrc" style="display: none" alt="" @load="originalLoaded = true" />
    </div>
  </v-avatar>
</template>

<script>
export default {
  name: 'ImageView',
  data() {
    return {
      loaded: false,
      delay: 50,
      styles: {},
      state: 'thumbnail',
      image: null,
      originalLoaded: false,
    }
  },
  props: {
    authPersonId: {
      type: [Number, String],
      required: true,
    },
    documentId: {
      type: [Number, String],
      required: true,
    },
    documentImage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thumbnailSrc() {
      return this.documentImage.file_url
    },
    imageSrc() {
      return this.image
    },
    isLoading() {
      return !this.loaded
    },
  },
  mounted() {
    this.getLargeImage()
  },
  methods: {
    getLargeImage() {
      this.image = this.documentImage.file_url
    },
    setStyles() {
      const rect = this.$refs.thumbnail.getBoundingClientRect()

      this.styles = {
        position: 'fixed',
        left: Math.round(rect.left) + 'px',
        top: Math.round(rect.top) + 'px',
        width: Math.round(rect.right - rect.left) + 'px',
        height: Math.round(rect.bottom - rect.top) + 'px',
        transition: 'width .5s, height .5s, top .5s, left .5s, background-color .5s',
      }
    },
    enlarge() {
      this.setStyles()
      this.state = 'enlarging'

      if (typeof this.timer !== 'undefined') {
        clearTimeout(this.timer)
      }

      const that = this
      that.timer = setTimeout(() => {
        that.$emit('enlarging')
        that.styles = {
          transition: 'width .7s, height .7s, top .7s, left .7s, background-color .7s',
        }

        if (typeof that.timer !== 'undefined') {
          clearTimeout(that.timer)
        }

        that.timer = setTimeout(function () {
          that.state = 'enlarged'
          that.$emit('enlarged')
        }, 700)
      }, 10)
    },
    reset() {
      if (this.state !== 'shrinking') {
        if (typeof this.timer !== 'undefined') {
          clearTimeout(this.timer)
        }

        const that = this
        this.timer = setTimeout(function () {
          that.state = 'shrinking'
          that.$emit('shrinking')
          that.setStyles()

          if (typeof that.timer !== 'undefined') {
            clearTimeout(that.timer)
          }

          that.timer = setTimeout(function () {
            that.state = 'thumbnail'
          }, 500)
        }, 0)
      } else {
        this.enlarge()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-view {
  width: 100%;
  height: 100%;
}

.image-view > .thumbnail {
  display: inline-block;
  cursor: zoom-in;

  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  img.default {
    max-width: 100%;
    vertical-align: middle;
  }
}

.image-view.active > .thumbnail {
  opacity: 0.3;
  filter: grayscale(100%);
}

.image-view .original {
  cursor: zoom-out;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background: unset !important;
  display: none;
}

.image-view .original > img {
  padding: 5rem 0 1rem;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.image-view .original.enlarging {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: zoom-out;
  z-index: 3;
}

.image-view .original.enlarged {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: zoom-out;
  z-index: 2;
}

.image-view .original.shrinking {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: zoom-in;
  z-index: 1;
}
</style>
