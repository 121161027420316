<template>
  <v-container class="ma-0 pa-0" style="position: relative" fluid>
    <DocumentPage
      v-for="page in pages"
      :key="page.id"
      :page-number="page.pageNumber"
      :page-id="page.id"
      :can-show-signatures="canShowSignatures"
      :custom-color="customColor"
      :sign="sign"
      @on-loaded="setPageIsLoaded(page)"
    />

    <DetailSignatures
      v-if="canShowSignatures && signaturesWithoutPosition.length"
      ref="signs"
      :contract="contract"
      :custom-color="customColor"
      :sign="sign"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DetailSignatures from '@/components/contract/DetailSignatures'
import DocumentPage from '@contract/components/DocumentPage'

export default {
  name: 'DocumentPages',
  components: {
    DetailSignatures,
    DocumentPage,
  },
  props: {
    documentLink: {
      type: String,
      default: undefined,
    },
    isPdfLoading: {
      type: Boolean,
      required: true,
    },
    canShowSignatures: {
      type: Boolean,
      default: false,
    },
    customColor: {
      type: String,
      default: 'primary',
    },
    sign: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      numberOfPages: 'pdfDocument/numberOfPages',
      signaturesWithoutPosition: 'signing/signaturesWithoutPosition',
    }),
    pages() {
      return new Array(this.numberOfPages).fill({}).map((item, index) => {
        const pageNumber = 1 + index

        return {
          id: `document-page-${pageNumber}`,
          loaded: false,
          pageNumber,
        }
      })
    },
  },
  methods: {
    ...mapActions({
      loadDocument: 'pdfDocument/load',
      setPagesLoaded: 'pdfDocument/setPagesLoaded',
    }),
    setPageIsLoaded(page) {
      page.loaded = true
      this.checkPages()
    },
    checkPages() {
      const pagesLoaded = 0 === this.pages.filter((page) => false === page.loaded)?.length

      if (pagesLoaded) {
        this.setPagesLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
