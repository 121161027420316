import i18n from '@/app/plugins/i18n'
import { defineStore } from 'pinia'
import { internalApiRequest } from '@/shared/services/apiService'
import { DocumentWithComments } from '@/features/contract/services/CommentsService'
import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'

export const fetchDocumentComments = (contractId, mode = 'comments') => {
  return internalApiRequest({
    method: 'GET',
    path: `api/v1/contracts/${contractId}/comments?mode=${mode}`,
  }).then((resp) => ({ contractId, comments: resp }))
}

export const useContractCommentsStore = defineStore('contractComments', {
  state: () => ({
    contracts: {},
  }),
  getters: {
    contractComments: (state) => {
      return (contractId) => state.contracts[`${contractId}`] || []
    },
  },
  actions: {
    async fetchContractComments(contractIds, mode) {
      const commentsRequest = []

      for (let contractId of contractIds) {
        commentsRequest.push(fetchDocumentComments(contractId, mode))
      }

      return Promise.allSettled(commentsRequest).then((results) => {
        for (const result of results) {
          this.contracts[`${result?.value?.contractId}`] =
            'rejected' === result?.value?.status ? [] : result?.value?.comments || []
        }
      })
    },
    async createDocumentCommentsReport({ contractId, title, signIdentities, locale = i18n.locale }) {
      const documentWithComments = new DocumentWithComments({
        contractId,
        title,
        signIdentities,
        locale,
      })

      await documentWithComments.generate()
    },
    async createDocumentsCommentsReport() {
      let title = null
      const pdfDocument = await PDFDocument.create()

      for (const contractDocument of Object.keys(this.contracts)) {
        const documentWithComments = new DocumentWithComments({
          contractId: contractDocument,
          title: `file-${contractDocument}`,
          signIdentities: [],
          locale: i18n.locale,
          onlyReturnFile: true,
        })

        const preparedFile = await documentWithComments.generate().then((resp) => resp)
        const loadedPreparedFile = await PDFDocument.load(preparedFile.file)

        if (null === title) {
          title = preparedFile.fileName
        }

        const copiedPagesOfPreparedFile = await pdfDocument.copyPages(
          loadedPreparedFile,
          loadedPreparedFile.getPageIndices(),
        )
        copiedPagesOfPreparedFile.forEach((page) => pdfDocument.addPage(page))
      }

      download(await pdfDocument.save(), `${title} - comments.pdf`, 'application/pdf')
    },
  },
})
