<template>
  <v-row class="pb-3" align="center" justify="start" no-gutters>
    <v-col class="col-12">
      <v-icon color="#7031B4" left>mdi-chat</v-icon>
      <span class="approval-note__owner">{{ owner }}:</span>
    </v-col>

    <v-col class="col-12">
      <span class="approval-note__note">"{{ text }}"</span>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ApprovalNote',
  props: {
    owner: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.approval-note {
  &__owner {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__note {
    color: #424242;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}
</style>
