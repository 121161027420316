<template>
  <v-row class="pb-5">
    <v-col cols="12" class="title font-weight-bold pb-0">
      {{ $t('general.basic_info') }}
    </v-col>

    <v-col cols="12">
      <v-row class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('general.name') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ givenName }}
        </v-col>
      </v-row>

      <v-row class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('general.surname') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ familyName }}
        </v-col>
      </v-row>

      <v-row class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('general.birth_date') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ birthdate }}
        </v-col>
      </v-row>

      <v-row class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium">
          {{ $t('personal_identification_number') }}:
        </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ birthNumber }}
        </v-col>
      </v-row>

      <v-row v-if="birthplace" class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('birthplace') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ birthplace }}
        </v-col>
      </v-row>

      <v-row v-if="birthCountry" class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('native_country') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ birthCountry }}
        </v-col>
      </v-row>

      <v-row v-if="primaryNationality" class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('main_nationality') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ primaryNationality }}
        </v-col>
      </v-row>

      <v-row v-if="nationalities" class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium">
          {{ $t('list_of_other_nationalities') }}:
        </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ nationalities }}
        </v-col>
      </v-row>

      <v-row class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium"> {{ $t('general.gender') }}: </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ gender }}
        </v-col>
      </v-row>

      <v-row v-if="pep" class="px-3" align="center" justify="start">
        <v-col class="col-4 col-md-2 py-1 pl-0 text-left font-weight-medium">
          {{ $t('aml.political.selected_political') }}:
        </v-col>
        <v-col class="py-1 pl-0 text-left">
          {{ pep }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BankIdContentCardBasicInfo',
  props: {
    bankIdData: {
      type: Object,
      required: true,
    },
    idAuth: {
      type: [String, Number],
      required: true,
    },
    idAuthBankId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    givenName() {
      return this.bankIdData?.given_name || this.$t('general.not_specified')
    },
    familyName() {
      return this.bankIdData?.family_name || this.$t('general.not_specified')
    },
    birthdate() {
      return this.formatDate(this.bankIdData?.birthdate)
    },
    birthNumber() {
      return this.bankIdData?.birth_number || this.$t('general.not_specified')
    },
    gender() {
      return (
        ('male' === this.bankIdData?.gender ? this.$t('general.gender.male') : this.$t('general.gender.female')) ||
        this.$t('general.not_specified')
      )
    },
    birthplace() {
      return this.bankIdData?.birthplace
    },
    birthCountry() {
      return this.bankIdData?.birth_country
    },
    primaryNationality() {
      return this.bankIdData?.primary_nationality
    },
    nationalities() {
      return this.bankIdData?.nationalities?.join(', ')
    },
    pep() {
      const pep = this.bankIdData?.pep

      if ('undefined' === pep || null === pep) {
        return null
      }

      return this.$t(`${pep ? 'general.yes' : 'general.no'}`)
    },
  },
  methods: {
    formatDate(date) {
      return moment(String(date)).format('DD.MM.YYYY') || this.$t('general.not_specified')
    },
  },
}
</script>

<style lang="scss" scoped></style>
