<template>
  <!--  TODO - Probably bad export-->
  <svg width="24px" height="24px" viewBox="-5 -5 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path
          fill="#b4c1d6"
          d="M4 0h10v2H4zM0 0h2v2H0zm4 4h10v2H4zM0 4h2v2H0zm4 4h10v2H4zM0 8h2v2H0zm4 4h10v2H4zm-4 0h2v2H0z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoControlList',
}
</script>

<style lang="scss" scoped></style>
