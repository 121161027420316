<template>
  <Data :showMenu="false" :loader="!loaded" v-if="contract" value>
    <v-container class="px-0 px-md contract-document-detail__wrapper" fluid>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col v-if="!isCounterpartyView" cols="12" class="pb-3">
          <v-btn text class="pl-0 font-weight-medium" @click="goToRoute('documentsTable')">
            <v-icon color="primary">mdi-arrow-left</v-icon>
            {{ $t('contract.detail.back') }}
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-row no-gutters align="center">
            <ContractState :state-type="contactState" @update="onUpdateContract" />

            <span class="contract-link__description ml-4">
              {{ `${$t('contract.contract_number')}:` }}
            </span>

            <span class="contract-link__number ml-2">
              {{ contractId }}
            </span>

            <v-btn class="ml-2" small icon @click="copyLinkToDetailContract()">
              <v-icon color="#95afda" small>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row align="center" justify="end" no-gutters>
            <v-col class="col-12 col-md pb-2 pb-md d-flex align-center">
              <v-text-field
                v-if="canEditEnvelopeName"
                v-model="contractEnvelopeName"
                :autofocus="canEditEnvelopeName"
                class="text-h5 col-5"
                solo
                flat
                hide-details
                @keydown.enter="changeEnvelopeName"
              ></v-text-field>

              <h1 v-else class="mb-0 font-weight-bold text-left text-break">
                {{ contractEnvelopeName }}
                <ArchiveState :archive-state="archiveState" />
              </h1>

              <v-btn v-if="hasChangableName" color="primary" class="ml-2" icon @click="changeEnvelopeName">
                <v-icon>
                  {{ `mdi-${canEditEnvelopeName ? 'content-save-outline' : 'pencil-outline'}` }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="hasAcceptAction" class="col col-md-auto pr-2">
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on, value }">
                  <v-btn color="primary" :loading="isProcessingSignOrApprove" dark v-bind="attrs" v-on="on">
                    {{ $t('contract.document_approval') }}
                    <v-icon right>{{ `mdi-chevron-${value ? 'up' : 'down'}` }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="options-menu">
                  <v-list-item link @click="approveContract">
                    <v-list-item-content class="options-menu__content--text">
                      {{ $t('general.approve') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="approveContractWithNote">
                    <v-list-item-content class="options-menu__content--text">
                      {{ $t('general.approve_with_comment') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col v-if="allowDocsAttachments" class="col col-md-auto pr-2">
              <v-btn
                :color="brandingPrimaryColor"
                class="pl-3 pr-1"
                outlined
                @click="addAttachmentsModal = true"
              >
                {{ $t('docs.add_attachment') }}
                <v-icon class="pa-0 ma-0 pl-1"> mdi-file-document-plus-outline </v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="hasLinks && !isCounterpartyView" class="col col-md-auto pr-2">
              <v-menu v-model="openMoreMenu" transition="slide-y-transition" bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="pl-3 pr-1"
                    :block="$vuetify.breakpoint.mobile"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('general.send') }}
                    <v-icon v-if="openMoreMenu" class="pl-2"> mdi-chevron-up </v-icon>
                    <v-icon v-else class="pl-2"> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>

                <v-list class="options-menu">
                  <v-list-item v-if="hasResendAction" class="px-O" @click.prevent="resendInvitation" link>
                    <v-list-item-content class="options-menu__content--text pr-4">
                      {{ $t('contract.resend') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasRejectAction" class="px-O" @click.prevent="modalDecline = true" link>
                    <v-list-item-content class="options-menu__content--text pr-4">
                      {{ $t('general.decline') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasMarkViewedAction" class="px-O" @click.prevent="setViewedWithoutSign" link>
                    <v-list-item-content class="options-menu__content--text pr-4">
                      {{ $t('contract.send_without_signature') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasCanSendReview" class="px-O" @click.prevent="toggleCommentMode('summary')" link>
                    <v-list-item-content class="options-menu__content--text pr-4">
                      {{ $t('review.send_back') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasCanStopReview"
                    class="px-O"
                    @click.prevent="toggleCommentMode('stopReview')"
                    link
                  >
                    <v-list-item-content class="options-menu__content--text pr-4">
                      {{ $t('review.stop') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider class="menu-divider"></v-divider>

                  <div class="resend-links px-4">
                    <span class="input__label input__label--value" style="font-weight: bolder">
                      {{ $t('contract.contract_link') }}:
                    </span>

                    <ShareLinks
                      :links="links"
                      :is-aml-links="contract.has_authentication"
                      containerClass="input contract-link-container"
                      inputClass="input__data contract-link-share-input"
                    />
                  </div>
                </v-list>
              </v-menu>
            </v-col>

            <v-col class="col-auto">
              <v-menu transition="slide-y-transition" bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="brandingPrimaryColor" class="pl-3 pr-1" outlined v-bind="attrs" v-on="on">
                    {{ $t('general.more') }}
                    <v-icon class="pa-0 ma-0 pl-1"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>

                <v-list class="options-menu">
                  <v-list-item
                    v-if="hasCanCommentAction"
                    class="px-0"
                    @click.prevent="toggleCommentMode('comments')"
                    link
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-comment-multiple-outline
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ commentsCountTitle }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasCanEditAction" class="px-0" @click.prevent="editContract()" link>
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-file-edit-outline
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.edit') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasCanExtendExpirationAction"
                    class="px-0"
                    @click.prevent="isExpirationModalOpened = true"
                    link
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense> mdi-lock-clock </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.validity_of_proposal') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasCanChangePartyAction" class="px-0" @click.prevent="editHeaderModal()" link>
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-pencil-outline
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.edit_header') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasCanDuplicateAction"
                    class="px-0"
                    @click.prevent="isDuplicateModalOpened = true"
                    link
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-content-duplicate
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.duplicate') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="hasCanDownloadPDFAction" class="px-0" @click.prevent="getPDFZip()" link>
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-tray-arrow-down
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.download_all') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="canDownloadContractDocumentsCommentsReport"
                    class="px-0"
                    @click.prevent="downloadDocumentsWithComments"
                    link
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-tray-arrow-down
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('download_documents_with_comments') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-0" link @click.prevent="downloadCheckList()">
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-format-list-checkbox
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.show_revisions') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <!--                  <v-list-item-->
                  <!--                    v-if="isEnabledMovingBetweenWorkspaces"-->
                  <!--                    class="px-0"-->
                  <!--                    link-->
                  <!--                    @click.prevent="showAvailableWorkspaces = true"-->
                  <!--                  >-->
                  <!--                    <v-list-item-content>-->
                  <!--                      <v-row align="center" justify="center" no-gutters>-->
                  <!--                        <v-col class="col-auto pl-4 pr-2">-->
                  <!--                          <v-icon color="#95afda" class="options-menu__content&#45;&#45;icon" dense>-->
                  <!--                            mdi-file-send-outline-->
                  <!--                          </v-icon>-->
                  <!--                        </v-col>-->

                  <!--                        <v-col class="options-menu__content&#45;&#45;text pr-4">-->
                  <!--                          {{ $t('docs.move') }}-->
                  <!--                        </v-col>-->
                  <!--                      </v-row>-->
                  <!--                    </v-list-item-content>-->
                  <!--                  </v-list-item>-->

                  <v-list-item v-if="hasAmlRevisionList" class="px-0" link @click.prevent="downloadAmlRevisionList">
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-format-list-checkbox
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('verification.revision_list') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="isDelegationPossible"
                    class="px-0"
                    link
                    @click="!isProposer ? (identityModal = true) : (showEditSignatureDialog = true)"
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-account-convert
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.replace.counterparty') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider class="menu-divider"></v-divider>

                  <v-list-item v-if="hasCanRejectAction" class="px-0" link @click.prevent="modalDecline = true">
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense> mdi-close-thick </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('general.decline') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasCanDeleteAction"
                    class="px-0"
                    link
                    @click.prevent="showDeleteContractDialog = true"
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-trash-can-outline
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('contract.remove_document') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasCanDeleteCompletedAction"
                    class="px-0"
                    link
                    @click.prevent="showCompletedDocumentDeleteDialog = true"
                  >
                    <v-list-item-content>
                      <v-row align="center" justify="center" no-gutters>
                        <v-col class="col-auto pl-4 pr-2">
                          <v-icon color="#95afda" class="options-menu__content--icon" dense>
                            mdi-trash-can-outline
                          </v-icon>
                        </v-col>

                        <v-col class="options-menu__content--text pr-4">
                          {{ $t('general.delete_pdf') }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-alert v-if="!signIdentitiesSuccessfullyLoaded" icon="mdi-alert" type="warning" class="mt-5" prominent dense>
        {{ $t('signature.placeholders.not_ready') }}
      </v-alert>

      <v-card v-if="isMobileDevice" class="mt-5" elevation="2">
        <div v-if="showUploadedAttachment && allowDocsAttachments" style="border: 2px solid gray;">
          <v-btn
            color="primary"
            class="pl-3 pr-1 mt-1 ml-1"
            outlined
            @click="closeUploadedAttachment"
          >
            {{ $t('docs.close_attachment') }}
            <v-icon class="pa-0 ma-0 pl-1"> mdi-close </v-icon>
          </v-btn>

          <v-btn
            color="primary"
            class="mt-1 ml-3"
            icon
            @click="downloadUploadedAttachment"
          >
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>

          <v-text-field
            v-model="uploadedAttachment.note"
            :label="$t('docs.attachment.comment')"
            outlined
            class="mt-5 mx-1" 
            hide-details
            :readonly="!isEditingUploadedAttachmentNote"
          >
            <template v-if="uploadedAttachment.doc_person_id === this.docsPersonId" v-slot:append>
              <v-icon v-if="!isEditingUploadedAttachmentNote" class="mr-2" @click="editUploadedAttachmentNote">mdi-pencil-outline</v-icon>

              <v-icon v-else class="mr-2" @click="saveUploadedAttachmentNote">mdi-content-save-outline</v-icon>

              <v-icon @click="deleteUploadedAttachmentNote">mdi-trash-can-outline</v-icon>
            </template>
          </v-text-field>

          <AttachmentsPages :attachment-link="uploadedAttachment.url" :attachment-type="uploadedAttachment.mime_type" />
        </div>

        <div v-else>
          <ContractDocumentTab
            v-for="contract in sideTabsContracts"
            :key="contract.id"
            :contract="contract"
            :main-contract-id="mainContractId"
            :workspace_id="workspaceId"
            :is-aml-active="showAmlContent"
            :show-sign-button="'sign' === sticker && hasSignAction && hasUnfinishedSignatures"
            :current-sign-identity="contractCurrentSignIdentity"
            @change-contract="changeContract"
            @sign-contract="sign"
          >
            <v-alert
              :value="hasNote"
              color="#FDEEB5"
              class="contract-note"
              icon="mdi-note-outline"
              transition="scale-transition"
            >
              <vue-simple-markdown :source="contractNote"></vue-simple-markdown>
            </v-alert>

            <DeletedDocumentView v-if="documentDeleted"></DeletedDocumentView>

            <DocumentView
              v-else
              :contractTemplate="contractTemplateContent"
              :can-edit-comments="canEditComments"
              :can-show-signatures="canDrawSignaturesState"
              :sign="sign"
            />

            <DetailSignatures
              v-if="
                canDrawSignaturesState &&
                ('form' === contract.type || isSignaturesOutsideOfDocumentsPages) &&
                !documentDeleted &&
                canViewTemplateSignatures
              "
              ref="signs"
              :contract="contract"
              :sign="sign"
            />

            <div class="mx-auto my-4 text-center">
              <v-menu v-if="hasAcceptAction" offset-y>
                <template v-slot:activator="{ attrs, on, value }">
                  <v-btn color="primary" :loading="isProcessingSignOrApprove" dark v-bind="attrs" v-on="on">
                    {{ $t('contract.document_approval') }}
                    <v-icon right>{{ `mdi-chevron-${value ? 'up' : 'down'}` }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="options-menu">
                  <v-list-item link @click="approveContract">
                    <v-list-item-content class="options-menu__content--text">
                      {{ $t('general.approve') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="approveContractWithNote">
                    <v-list-item-content class="options-menu__content--text">
                      {{ $t('general.approve_with_comment') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </ContractDocumentTab>

          <AmlActivator v-if="canShowAmlContent" v-model="showAmlContent" :aml-count="authsCount">
            <VerificationContent v-if="showAmlContent" :main-contract-id="mainContractId" />
          </AmlActivator>
        </div>

        <v-row v-if="allowDocsAttachments" class="ma-3">
          <div class="ma-1 col-12 pa-0" v-for="attachment in uploadedAttachments" :key="attachment.id">
            <v-chip
              color="blue"
              label
              outlined
              @click="openUploadedAttachment(attachment)"
            >
            <v-avatar left>
              <v-icon>mdi-file-outline</v-icon>
            </v-avatar>

              {{ attachment.title }}

              <v-icon right v-if="attachment.note" class="ml-2" color="blue">mdi-comment-text-outline</v-icon>
            </v-chip>
          </div>
        </v-row>

        <ContractDocumentInfoBox
          :contract="contract"
          @edit-contract="editContract"
          :is-counterparty="isCounterpartyView"
        />
      </v-card>

      <v-card v-else class="mt-5" elevation="2">
        <v-row align="stretch" justify="space-between" no-gutters>
          <v-col class="col-md-3" style="background-color: #f2f6fc">
            <ContractDocumentTab
              v-for="contract in sideTabsContracts"
              :key="contract.id"
              :contract="contract"
              :main-contract-id="mainContractId"
              :workspace_id="workspaceId"
              :is-aml-active="showAmlContent"
              :current-sign-identity="contractCurrentSignIdentity"
              @change-contract="changeContract"
              @sign-contract="sign"
            />

            <AmlActivator v-if="canShowAmlContent" v-model="showAmlContent" :aml-count="authsCount" />

            <v-row v-if="allowDocsAttachments" class="ma-3">
              <div class="ma-1 col-12 pa-0" v-for="attachment in uploadedAttachments" :key="attachment.id">
                <v-chip
                  color="blue"
                  label
                  outlined
                  @click="openUploadedAttachment(attachment)"
                >
                <v-avatar left>
                  <v-icon>mdi-file-outline</v-icon>
                </v-avatar>

                  {{ attachment.title }}

                  <v-icon right v-if="attachment.note" class="ml-2" color="blue">mdi-comment-text-outline</v-icon>
                </v-chip>
              </div>
            </v-row>

            <ContractDocumentInfoBox
              :contract="contract"
              @edit-contract="editContract"
              :is-counterparty="isCounterpartyView"
            />
          </v-col>

          <v-col class="col-md-8 contract-document-detail__content-view">
            <v-row v-if="!showAmlContent" align="center" justify="space-between" no-gutters> </v-row>

            <div style="position: relative">
              <VerificationContent v-if="showAmlContent" :main-contract-id="mainContractId" />

              <template v-else>
                <div
                  v-if="!canManageComments && ((showSticker && hasSignAction) || (showSticker && hasApproveAction))"
                  class="contract-preview__content-document--sign-info"
                  :style="{ 'top: 0 !important': hasCanCommentAction }"
                >
                  <span
                    class="contract-preview__content-document--sign-info-close icon-close"
                    @click="sticker = null"
                  ></span>
                  <span>{{ $t('contract.review.info') }}</span
                  ><br />
                  <span
                    v-html="
                      sticker === 'approve' ? $t('contract.review.action_agree') : $t('contract.review.action_sign')
                    "
                    class="strong-text"
                    @click="scrollToSigns"
                  ></span>
                </div>

                <v-expand-transition>
                  <v-row v-if="hasNote" align="center" justify="center" no-gutters>
                    <v-col cols="12" class="pa-5 pb-0">
                      <v-alert
                        :value="hasNote"
                        color="#FDEEB5"
                        class="contract-note mb-0"
                        icon="mdi-note-outline"
                        transition="scale-transition"
                      >
                        <vue-simple-markdown :source="contractNote"></vue-simple-markdown>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-expand-transition>

                <v-row v-if="!showAmlContent && !showUploadedAttachment" align="center" justify="end" no-gutters>
                  <ProgressLine
                    v-if="
                      contractTemplateContent && !isSignedByCounterparty && !isProposer && !isApprovedByCounterparty
                    "
                    :total="getTemplateFields ? getTemplateFields.length : 1"
                    :progress="getFilledInputs"
                  />
                  <v-col cols="auto" class="pt-5 pb-0">
                    <v-dialog v-model="fullscreen" fullscreen>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon dark v-bind="attrs" v-on="on">
                          <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-toolbar dense>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="fullscreen = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>

                        <v-card-text class="pa-5">
                          <DocumentView
                            :contractTemplate="contractTemplateContent"
                            :can-edit-comments="canEditComments"
                            :can-show-signatures="canDrawSignaturesState"
                            :sign="sign"
                          />
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="auto" class="pa-5 pb-0">
                    <v-btn v-if="hasCanCommentAction" icon @click="toggleCommentMode('comments')">
                      <v-badge
                        color="primary"
                        overlap
                        bordered
                        :content="contractCommentsCount"
                        :value="contractCommentsCount"
                      >
                        <v-icon color="primary">mdi-comment-multiple-outline</v-icon>
                      </v-badge>
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-if="showUploadedAttachment && allowDocsAttachments" style="border: 2px solid gray;">
                  <v-toolbar class="mr-3 mt-3" dense flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      class="mr-1"
                      icon
                      @click="downloadUploadedAttachment"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="mt-1 pl-3 pr-1 mb-2"
                      outlined
                      @click="closeUploadedAttachment"
                    >
                      {{ $t('docs.close_attachment') }}
                      <v-icon class="pa-0 ma-0 pl-1"> mdi-close </v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-text-field
                    v-model="uploadedAttachment.note"
                    :label="$t('docs.attachment.comment')"
                    outlined
                    class="mx-10 mt-2" 
                    hide-details
                    :readonly="!isEditingUploadedAttachmentNote"
                  >
                  <template v-if="uploadedAttachment.doc_person_id === this.docsPersonId" v-slot:append>
                    <v-icon v-if="!isEditingUploadedAttachmentNote" class="mr-2" @click="editUploadedAttachmentNote">mdi-pencil-outline</v-icon>

                    <v-icon v-else class="mr-2" @click="saveUploadedAttachmentNote">mdi-content-save-outline</v-icon>

                    <v-icon @click="deleteUploadedAttachmentNote">mdi-trash-can-outline</v-icon>
                  </template>
                  </v-text-field>

                  <AttachmentsPages :attachment-link="uploadedAttachment.url" :attachment-type="uploadedAttachment.mime_type" />
                </div>

                <div v-else>
                  <DeletedDocumentView v-if="documentDeleted"></DeletedDocumentView>

                  <DocumentView
                    v-else
                    :contractTemplate="contractTemplateContent"
                    :can-edit-comments="canEditComments"
                    :can-show-signatures="canDrawSignaturesState"
                    :sign="sign"
                  />

                  <DetailSignatures
                    v-if="
                      canDrawSignaturesState &&
                      ('form' === contract.type || isSignaturesOutsideOfDocumentsPages) &&
                      !documentDeleted &&
                      canViewTemplateSignatures
                    "
                    ref="signs"
                    :contract="contract"
                    :sign="sign"
                  />
                </div>
              </template>

              <div class="mx-auto my-10 text-center">
                <v-menu v-if="hasAcceptAction" offset-y>
                  <template v-slot:activator="{ attrs, on, value }">
                    <v-btn color="primary" :loading="isProcessingSignOrApprove" dark v-bind="attrs" v-on="on">
                      {{ $t('contract.document_approval') }}
                      <v-icon right>{{ `mdi-chevron-${value ? 'up' : 'down'}` }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="options-menu">
                    <v-list-item link @click="approveContract">
                      <v-list-item-content class="options-menu__content--text">
                        {{ $t('general.approve') }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="approveContractWithNote">
                      <v-list-item-content class="options-menu__content--text">
                        {{ $t('general.approve_with_comment') }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-col>

          <v-col class="col-md-1" style="background-color: #f2f6fc">
            <DocumentPagePreviews v-if="contract.type === 'future-doc'" />
          </v-col>
        </v-row>
      </v-card>

      <Modal v-if="isEditHeaderModalOpened" @close="isEditHeaderModalOpened = false" :enableClose="true">
        <h2 class="bigger-h2 new-user-title">
          {{ $t('contract.edit_header.heading') }}
        </h2>

        <div class="party-modal-header">
          <div v-if="areAvailablePartiesLoaded">
            <div v-if="selectedUsers.length" class="available-user__message">
              {{ $t('contract.edit_header.description') }}
              <strong>{{ selectedUsers[0].workspace_name }}</strong>
              {{ $t('contract.edit_header.description.users') }}
              <strong>{{ selectedUsers[0].fullname }}</strong>
            </div>

            <div class="available-user__box">
              <div v-if="selectedUsers.length" class="available-user__selected">
                <div class="available-user__selected__item" v-for="(user, index) in selectedUsers" v-bind:key="index">
                  <div class="available-user__selected__name">
                    <div class="available-user__selected__name-inner">
                      {{ user.fullname }}
                    </div>
                    <div class="available-user__selected__workspace-name">
                      {{ user.workspace_name }}
                    </div>
                  </div>
                  <div class="available-user__selected__close" @click="removeUser(user)">
                    <span class="available-user__selected__close-icon">+</span>
                  </div>
                </div>
              </div>

              <div v-if="availableUsers.length" class="available-users">
                <div
                  @click="setSelectedUser(user)"
                  v-for="(user, index) in availableUsers"
                  class="available-user"
                  v-bind:key="index"
                >
                  <div class="available-user__ico">
                    <span class="icon-circle-plus"></span>
                  </div>
                  <div class="available-user__data">
                    <div class="available-user__name">{{ user.fullname }}</div>
                    <div class="available-user__workspace">
                      <p>{{ user.workspace_name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="padding: 30px 0">
              <Button :loading="loader" @click="changeHeader()" type="primary">{{ $t('general.save_changes') }}</Button>
            </div>
          </div>

          <div v-else>
            <Loader class="register-loader" style="text-align: center; padding-left: 0" />
          </div>
        </div>
      </Modal>

      <ContractDeclineDialog v-model="modalDecline" :contract-id="contract.id" />

      <DuplicateContract v-model="isDuplicateModalOpened" :contract="contract" />

      <ExtendExpiration
        v-model="isExpirationModalOpened"
        :contracts="[contract]"
        @reloadAfterExtend="fetchContractData"
      />
    </v-container>

    <SConfirmDialog
      v-model="showDeleteContractDialog"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="deleteContract"
    />

    <SConfirmDialog
      v-model="showCompletedDocumentDeleteDialog"
      :message="`${$t('contract.delete_pdf.info')}`"
      @confirmation-callback="deleteContractPdf"
    />

    <v-overlay :value="overlay">
      <v-progress-circular color="primary" size="64" width="5" indeterminate></v-progress-circular>
    </v-overlay>

    <SConfirmDialog
      v-model="showRemoveSignatureDialog"
      :message="`${$t('contract.edit_confirm_delete')}`"
      @confirmation-callback="resetToDraft"
    />

    <SConfirmDialog
      v-model="showRemoveHeaderSignatureDialog"
      :message="`${$t('contract.edit_confirm_delete')}`"
      @confirmation-callback="editContractHeader"
    />

    <Identity
      v-if="identityModal"
      :modalConfig="identityModal"
      @upsertIdentity="delegateSignature"
      @close="identityModal = false"
    />

    <EditSignatureDialog
      v-if="showEditSignatureDialog"
      v-model="showEditSignatureDialog"
      :loading="loading"
      :sign-identity="signIdentity"
      :participant-name="participantName"
      @replace-signee="delegateSignature"
    />

    <v-dialog :value="showApproveDialog" max-width="400">
      <v-card>
        <v-card-title class="text-center">
          {{ $t('general.approve_with_comment') }}

          <v-spacer></v-spacer>
          <v-btn icon @click="showApproveDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-3 pb-0">
          <v-textarea v-model="approvalNote" :counter="300" rows="3" outlined></v-textarea>
        </v-card-text>

        <v-card-actions class="pb-3">
          <v-btn
            class="mx-auto"
            color="primary"
            :loading="isProcessingSignOrApprove"
            :disabled="2 > approvalNote.length && 300 <= approvalNote.length"
            dark
            @click="approveContract"
          >
            {{ $t('signature.agree_with_contract') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAttachmentsModal" max-width="800">
      <v-card>
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="addAttachmentsModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-1 pb-3 mx-auto">
          <AttachmentsUploader
            class="mx-auto"
            :docs-id="docsId"
            :docs-person-id="docsPersonId"
            @close-dialog="addAttachmentsModal = false"
            @reload-attachments="fetchAttachments"
          />
        </div>
      </v-card>
    </v-dialog>

    <!--    <v-dialog v-model="showAvailableWorkspaces" max-width="500px">-->
    <!--      <v-card class="pb-7" elevation="0">-->
    <!--        <v-toolbar class="pt-1" dense flat>-->
    <!--          <v-spacer></v-spacer>-->
    <!--          <v-btn icon @click="showAvailableWorkspaces = false">-->
    <!--            <v-icon>mdi-close</v-icon>-->
    <!--          </v-btn>-->
    <!--        </v-toolbar>-->

    <!--        <v-card-title>-->
    <!--          <h2 class="font-weight-medium mx-auto mb-0 pb-3 text-center">-->
    <!--            {{ $t('docs.move') }}-->
    <!--          </h2>-->
    <!--        </v-card-title>-->

    <!--        <v-card-subtitle class="text-center">-->
    <!--          {{ $t('docs.move.info') }}-->
    <!--        </v-card-subtitle>-->

    <!--        <v-card-actions>-->
    <!--          <v-row no-gutters align="center" justify="center">-->
    <!--            <v-col cols="8">-->
    <!--              <v-select-->
    <!--                v-model="selectedWorkspace"-->
    <!--                background-color="white"-->
    <!--                :items="profileWorkspaces"-->
    <!--                item-text="title"-->
    <!--                :label="$t('docs.move.hint')"-->
    <!--                :placeholder="$t('docs.move.hint')"-->
    <!--                hide-details-->
    <!--                return-object-->
    <!--                outlined-->
    <!--              >-->
    <!--              </v-select>-->
    <!--            </v-col>-->

    <!--            <v-col cols="2" class="pl-3">-->
    <!--              <v-btn-->
    <!--                class="primary"-->
    <!--                dark-->
    <!--                x-large-->
    <!--                block-->
    <!--                outlined-->
    <!--                :disabled="isWorkspaceSelected"-->
    <!--                @click="moveDocument"-->
    <!--              >-->
    <!--                <v-icon>mdi-check</v-icon>-->
    <!--              </v-btn>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->
  </Data>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { parseDate } from '@/services/helpers/parseDate.js'
import { apiAuthorizedRequest } from '@/services/ApiService'
import { getDocumentPreviewImages } from '@/common/reusable/contractFunctions'
import { getSignatureVerticalPosition } from '@/common/reusable/signatureFunctions'
import { ContractService } from '@/services/ContractService.js'
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js'
import { fetchEnvelopTitle, updateEnvelopTitle } from '@/services/ContractService.js'
import Loader from '@/components/Loader.vue'
import ContractState from '@contract/components/ContractState'
import AmlActivator from '@contract/components/AmlActivator'
import DocumentView from '@contract/components/DocumentView'
import ContractDocumentInfoBox from '@contract/components/ContractDocumentInfoBox'

import ShareLinks from '@/components/ShareLinks.vue'
import Modal from '@/components/Modal.vue'
import DetailSignatures from '@/components/contract/DetailSignatures.vue'
import DuplicateContract from '@/views/dashboard/components/DuplicateContract.vue'
import ExtendExpiration from '@/views/dashboard/components/ExtendExpiration.vue'
import VerificationContent from '@contract/components/VerificationContent'
import ContractDocumentTab from '@contract/components/ContractDocumentTab'
import DocumentPagePreviews from '@contract/components/DocumentPagePreviews'
import DeletedDocumentView from '@contract/components/DeletedDocumentView'
import axios from 'axios'
import { goToLinkWithTarget } from '@/common/reusable/routing'
import ArchiveState from '@contract/components/ArchiveState'
import SConfirmDialog from '@/common/components/SConfirmDialog'
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker'
import { internalApiRequest } from '@/shared/services/apiService'
import Identity from '@/views/create/components/Identity'
import ProgressLine from '@/components/ProgressLine'
import FeatureFlags from '@/common/reusable/featureFlagsChecker'

import { mapStores } from 'pinia'
import { useContractCommentsStore } from '@contract/stores/contractCommentsStore'
import { useTemplateFieldsStore } from '@/features/contract/stores/templateFieldsStore'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import { hasSignIdentitySignAction } from '@/features/contracts/stores/contractSignTypes'
import {
  approveContractAsRegisteredUser,
  getContractAmlRevisionList,
  getContractCheckList,
} from '@contract/services/contractService'
import { useBrandingStore } from '@/stores/branding'
import { environment } from '@/config/environment'
import { publicApiRequest } from '@/services/ApiService'

import AttachmentsUploader from '@/features/contract/components/AttachmentsUploader.vue'
import AttachmentsPages from '@/features/contract/components/AttachmentsPages.vue'
import download from 'downloadjs'

export default {
  name: 'ContractDocumentDetail',
  components: {
    SConfirmDialog,
    ArchiveState,
    DeletedDocumentView,
    DocumentPagePreviews,
    ContractDocumentTab,
    VerificationContent,
    ContractDocumentInfoBox,
    AmlActivator,
    DocumentView,
    ContractState,
    ContractDeclineDialog: () => import('@/components/ContractDeclineDialog'),
    Modal,
    DuplicateContract,
    ExtendExpiration,
    Loader,
    DetailSignatures,
    ShareLinks,
    Identity,
    EditSignatureDialog: () => import('@/features/signing/components/EditSignatureDialog'),
    ProgressLine,
    AttachmentsUploader,
    AttachmentsPages,
  },
  data() {
    return {
      approvalNote: '',
      showApproveDialog: false,
      isProcessingSignOrApprove: false,
      overlay: false,
      showDeleteContractDialog: false,
      reloadSignatures: false,
      links: [],
      showAmlContent: false,
      snackbar: true,
      sticker: null,
      loader: false,
      showImages: '',
      showDetail: false,
      loadingPDF: false,
      partyHeader: {
        firstname: '',
        lastname: '',
        city: '',
        zip: '',
      },
      isDuplicateModalOpened: false,
      isExpirationModalOpened: false,
      commentsModalMode: null,
      canManageComments: false,
      isEditHeaderModalOpened: false,
      otherReasonMessage: '',
      selectedReason: false,
      changeMind: false,
      notForMe: false,
      dontUnderstand: false,
      otherReason: false,
      modalDecline: false,
      loading: false,
      loaded: false,
      created: '',
      showFull: false,
      availableUsers: [],
      selectedUsers: '',
      areAvailablePartiesLoaded: false,
      loadingCheckList: false,
      amlLink: [],
      contractApproved: false,
      fullscreen: false,
      showCompletedDocumentDeleteDialog: false,
      signIdentitiesSuccessfullyLoaded: true,
      editEnvelopeName: false,
      contractEnvelopeName: '',
      showRemoveSignatureDialog: false,
      showRemoveHeaderSignatureDialog: false,
      // showAvailableWorkspaces: false,
      selectedWorkspace: null,
      identityModal: null,
      showEditSignatureDialog: false,
      openMoreMenu: false,
      addAttachmentsModal: false,
      showUploadedAttachment: false,
      uploadedAttachments: [],
      uploadedAttachment: {},
      isEditingUploadedAttachmentNote: false,
      uploadedAttachmentPages: undefined,
    }
  },
  computed: {
    ...mapStores(useContractCommentsStore, useTemplateFieldsStore, useBrandingStore),
    ...mapGetters({
      auths: 'verification/auths',
      contract: 'contract',
      contractComments: 'contractComments',
      contractCommentsCount: 'contractCommentsCount',
      documentInfo: 'pdfDocument/documentInfo',
      profile: 'profile',
      userIdentity: 'userIdentity',
      signIdentity: 'signIdentity',
      myIdentity: 'myIdentity',
      isAllPagesLoaded: 'pdfDocument/isAllPagesLoaded',
      unfinishedSignatures: 'signing/unfinishedSignatures',
      loadedCurrentSignIdentity: 'contractSignIdentities/signIdentities',
      loadedContractCurrentSignIdentity: 'contractSignIdentities/currentSignIdentity',
      workspaceById: 'workspaceById',
    }),
    getTemplateFields() {
      return this.templateFieldsStore?.getTemplateFieldsData
    },
    getFilledInputs() {
      return this.templateFieldsStore?.filledInputsCount
    },
    workspaceId() {
      return this.$route.params?.workspace_id
    },
    currentWorkspace() {
      return this.workspaceById(this.workspaceId)
    },
    isWorkspaceSelected() {
      return !this.selectedWorkspace
    },
    isDelegationByCounterpartyDisabled() {
      return hasFeatureFlag(this.contract, 'disableDelegationByCounterparty')
    },
    isDelegationByProposerDisabled() {
      return hasFeatureFlag(this.contract, 'proposerSignatureDelegationDisabled')
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id)
    },
    archiveState() {
      return this.contract?.state_trusted_archive || null
    },
    canDrawSignaturesState() {
      return (
        !this.reloadSignatures &&
        !this.canManageComments &&
        (('form' === this.contract.type && this.contract.state !== 'draft') ||
          (this.isAllPagesLoaded && this.hasSignAction))
      )
    },
    canEditEnvelopeName() {
      return this.editEnvelopeName
    },
    hasChangableName() {
      return (
        !this.contract.is_attachment &&
        ('draft' === this.contactState || 'pending' === this.contactState || 'completed' === this.contactState)
      )
    },
    isDelegationPossible() {
      if (
        !this.signIdentity?.is_signed &&
        !!this.contractCurrentSignIdentity &&
        !this.signIdentity?.variable_position &&
        'pending' === this.contactState
      ) {
        return this.isProposer ? !this.isDelegationByProposerDisabled : !this.isDelegationByCounterpartyDisabled
      }

      return false
    },
    isSignaturesOutsideOfDocumentsPages() {
      return hasFeatureFlag(this.contract, 'hasAppendedSignsToTheEnd')
    },
    contractSignIdentities() {
      return this.loadedCurrentSignIdentity || this.contract?.sign_identities || []
    },
    contractCurrentSignIdentity() {
      return this.loadedContractCurrentSignIdentity || this.contract?.current_sign_identity || null
    },
    hasUnfinishedSignatures() {
      return (
        this.contractCurrentSignIdentity.is_signable_by_current_user &&
        this.unfinishedSignatures(this.contractCurrentSignIdentity.id)?.length <= 1
      )
    },
    canEditComments() {
      return this.canManageComments
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
    isProposer() {
      return this.contractCurrentSignIdentity?.is_proposer || false
    },
    isCounterpartyView() {
      return 'true' === this.$route.query?.counterparty || this.$route.query?.counterparty || false
    },
    mainContractId() {
      return this.contract?.main_contract_id || this.contract?.id
    },
    contractId() {
      return this.$route.params?.contract_id
    },
    contactState() {
      return this.contract?.state || 'draft'
    },
    authsCount() {
      return this.contract?.count_counterparties_auth || null
    },
    commentsCountTitle() {
      return `${this.$t('review.comments.title')}${
        this.contractCommentsCount !== 0 ? ` (${this.contractCommentsCount})` : ''
      }`
    },
    contractNote() {
      return this.contract?.note || ''
    },
    hasLinks() {
      return this.links?.length > 0 || false
    },
    hasResendAction() {
      return this.contract?.current_user?.can_resend_notification || false
    },
    hasAcceptAction() {
      if ('draft' === this.contract?.state) {
        return false
      }

      return this.contract?.current_user?.can_accept || false
    },
    hasApproveAction() {
      return (
        (this.contract?.state !== 'draft' &&
          this.contract?.sign_identities?.filter((s) => {
            return ['approve'].includes(s.contract_role)
          }).length > 0) ||
        false
      )
    },
    hasSignAction() {
      return this.contract?.state !== 'draft'
    },
    hasRejectAction() {
      return this.contract?.current_user?.can_reject_this_contract || false
    },
    hasMarkViewedAction() {
      return this.contract?.current_user?.can_mark_viewed || false
    },
    hasCanSendReview() {
      return this.contract?.current_user?.can_send_review || false
    },
    hasCanStopReview() {
      return this.contract?.current_user?.can_stop_review || false
    },
    hasCanCommentAction() {
      return this.contract?.current_user?.can_comment || false
    },
    hasCanEditAction() {
      return this.contract?.current_user?.can_edit || false
    },
    hasCanExtendExpirationAction() {
      return this.contract?.current_user?.can_extend_expiration || false
    },
    hasCanChangePartyAction() {
      return this.contract?.current_user?.can_change_party || false
    },
    hasCanDuplicateAction() {
      return this.contract?.current_user?.can_duplicate || false
    },
    hasCanDownloadPDFAction() {
      if ('undefined' === typeof this.contract?.current_user?.can_download_pdf) {
        return true
      }

      return this.contract?.current_user?.can_download_pdf
    },
    canDownloadContractDocumentsCommentsReport() {
      return this.hasCanDownloadPDFAction && hasFeatureFlag(this.activeWorkspace, 'mergePdfCommentsEnabled')
    },
    hasAmlRevisionList() {
      return !this.isCounterpartyView && this.contract.state !== 'draft' && this.contract?.has_authentication
    },
    hasCanRejectAction() {
      return this.contract?.current_user?.can_reject_this_contract || false
    },
    hasCanDeleteAction() {
      return this.contract?.current_user?.can_delete || false
    },
    hasCanDeleteCompletedAction() {
      return this.archiveState?.status !== 'archived' && this.contract?.current_user?.can_delete_documents
    },
    hasNote() {
      const isWorkspaceMember = this.profile?.workspaces?.find((workspace) => {
        return parseInt(workspace.id) === parseInt(this.contract?.workspace_id)
      })

      return !!this.contract?.note && !!isWorkspaceMember
    },
    canShowAmlContent() {
      return !this.isCounterpartyView && this.contract?.has_authentication
    },
    sideTabsContracts() {
      return this.contract.attachments && this.contract.attachments.length ? this.contract.attachments : [this.contract]
    },
    contractDocumentContent() {
      return this.showImages || null
    },
    contractTemplateContent() {
      return this.contract?.template?.content || null
    },
    documentPreviews() {
      return getDocumentPreviewImages(this.contractDocumentContent)
    },
    showSticker() {
      return this.sticker !== null && !this.showAmlContent && this.signIdentitiesSuccessfullyLoaded
    },
    canSignDraft() {
      return this.contract?.current_user?.can_sign_draft || false
    },
    documentDeleted() {
      return !!this.contract?.deleted_by_client
    },
    participantName() {
      if (this.signIdentity?.variable_position && !this.signIdentity.email) {
        return `${this.signIdentity.variable_position}`
      }

      return `${this.signIdentity.firstname} ${this.signIdentity.lastname}`
    },
    canViewTemplateSignatures() {
      if ('form' !== this.contract?.type) {
        return true
      }

      if (this.isProposer) {
        return this.isSignedByCounterparty || this.contractCurrentSignIdentity?.is_signed || this.allTemplatesCompleted
      }

      return (this.templateFieldsStore.isTemplateCompleted || this.isSignedByCounterparty) && this.allTemplatesCompleted
    },
    isSignedByCounterparty() {
      return this.contract?.sign_identities?.some((signIdentity) => !signIdentity.is_proposer && signIdentity.is_signed)
    },
    isApprovedByCounterparty() {
      return this.contract?.sign_identities?.some(
        (signIdentity) => !signIdentity.is_proposer && signIdentity.is_approved,
      )
    },
    allTemplatesCompleted() {
      if (this.isSignedByCounterparty) {
        return true
      }

      return this.templateFieldsStore.areAllTemplatesCompleted
    },
    brandingPrimaryColor() {
      return this.brandingStore.brandingPrimaryColor
    },
    isEnabledRedirectUserAfterSign() {
      return FeatureFlags.redirectUserAfterSignEnabled(this.contract)
    },
    docsId() {
      return this.contract?.docs_id;
    },
    docsPersonId() {
      return this.contract?.docsPersonId;
    },
    allowDocsAttachments() {
      return hasFeatureFlag(this.contract, 'allowDocsAttachments')
    }
  },
  watch: {
    canShowAmlContent: {
      handler: function (val) {
        if ('draft' === this.contract.state) {
          this.showAmlContent = val
        }
      },
    },
    canViewTemplateSignatures: {
      handler: function () {
        this.setupSticker()
      },
    },
  },
  created() {
    this.resetSignIdentities()
    this.resetCurrentSignIdentity()
    this.fetchContractData()
    this.showAmlIdentificationCard()

    window.dataLayer.push({ event: 'contract_proposal_view' })
  },
  mounted() {
    this.getEnvelopeName()

    if (this.$route?.query?.bankIdResult) {
      if ('failure' === this.$route?.query?.bankIdResult) {
        this.$notification.error(`${this.$t('contract.sign.error_occurred')} - ${this.$route.query?.error}`)
      } else if ('success' === this.$route?.query?.bankIdResult) {
        this.$notification.success(this.$t('signing.signature_inserted'))
      }

      setTimeout(() => {
        this.$router.replace({ query: null })
      }, 2000)
    }

    if (this.$route?.query?.handySignaturResult) {
      if ('failure' === this.$route?.query?.handySignaturResult) {
        this.$notification.error(`${this.$t('contract.sign.error_occurred')}`)
      } else if ('success' === this.$route?.query?.handySignaturResult) {
        this.$notification.success(this.$t('signing.signature_inserted'))
      }

      setTimeout(() => {
        this.$router.replace({ query: null })
      }, 2000)
    }
  },
  destroyed() {
    this.setSignatures([])

    if (null !== window.onpopstate) {
      window.onpopstate = null
    }
  },
  methods: {
    ...mapActions({
      fetchAuthState: 'verification/fetchAuthState',
      fetchCurrentSignIdentity: 'contractSignIdentities/fetchCurrentSignIdentity',
      fetchDocumentWithLink: 'pdfDocument/fetchDocumentWithLink',
      fetchSignIdentities: 'contractSignIdentities/fetchSignIdentities',
      setSignatures: 'signing/setSignatures',
      resetCurrentSignIdentity: 'contractSignIdentities/resetCurrentSignIdentity',
      resetSignIdentities: 'contractSignIdentities/resetSignIdentities',
    }),
    downloadUploadedAttachment() {
      fetch(this.uploadedAttachment.url)
        .then(async (resp) => {
          download(await resp.arrayBuffer(), `${this.uploadedAttachment.title}`, this.uploadedAttachment.mimeType)
        })
    },
    addNoteToUploadedNote() {
      this.uploadedAttachment.note = '';
    },
    editUploadedAttachmentNote() {
      this.isEditingUploadedAttachmentNote = true;
    },
    saveUploadedAttachmentNote() {
      internalApiRequest({
        method: 'PUT',
        path: `api/v1/docs/attachment/${this.uploadedAttachment.id}`,
        data: {
          note: this.uploadedAttachment.note,
        }
      }).then(() => {
        this.fetchAttachments()

        this.isEditingUploadedAttachmentNote = false;
        this.$notification.success(this.$t('docs.uploaded_attachment.comment_edited'))
      }).catch((error) => {
        this.$notification.error(error.response?.data || error)
      });
    },
    deleteUploadedAttachmentNote() {
      internalApiRequest({
        method: 'PUT',
        path: `api/v1/docs/attachment/${this.uploadedAttachment.id}`,
        data: {
          note: '',
        }
      }).then(() => {
        this.fetchAttachments()

        this.uploadedAttachment.note = ''
        this.$notification.success(this.$t('docs.uploaded_attachment.comment_deleted'))
      }).catch((error) => {
        this.$notification.error(error.response?.data || error)
      });
    },
    closeUploadedAttachment() {
      this.showUploadedAttachment = false;
    },
    async openUploadedAttachment(attachment) {
      if(this.showUploadedAttachment) {
        await this.closeUploadedAttachment();
      }

      if(attachment.mime_type === 'application/pdf' || attachment.mime_type.startsWith('image/')) {
        this.uploadedAttachment = attachment
        this.showUploadedAttachment = true;
      } else {
        return (
          fetch(attachment.url)
            .then(async (resp) => {
              download(await resp.arrayBuffer(), `${attachment.title}`, attachment.mimeType)
            })
        )
      }
    },
    fetchAttachments() {
      internalApiRequest({
        method: 'GET',
        path: `api/v1/docs/${this.contract.docs_id}/attachment`,
      }).then((resp) => {
        this.uploadedAttachments = resp
      }).catch((error) => {
        this.$notification.error(error.response?.data || error)
      });
    },
    copyLinkToDetailContract() {
      const linkToDetailContract = `${environment.getAppUrl()}/dashboard/workspace/${this.workspaceId}/documents/${
        this.contractId
      }`
      navigator?.clipboard?.writeText(linkToDetailContract)

      this.$notification.success(this.$t('general.content_copied'))
    },
    async updateTemplateFieldsData() {
      const newTemplateData = this.getTemplateFields
        .filter((field) => !!field?.value)
        .map((templateField) =>
          Object.assign(
            {},
            {
              id: templateField.id,
              value: templateField.value,
            },
          ),
        )

      if ('draft' !== this.contract.state && 'pending' !== this.contract.state) {
        throw new Error(`${this.$t('contract.templates_noedit')}`)
      }

      if (0 === newTemplateData.length) {
        return
      }

      if (!this.contract.current_sign_identity?.workspace_id && !this.contract?.sign_identity?.workspace_id) {
        return publicApiRequest({
          authorization: 'ApiToken',
          authToken: this.$route.params.hash,
          method: 'PATCH',
          endpoint: `api/client/v1/contract/${this.contract?.id}/form/answers`,
          data: newTemplateData,
        }).catch((error) => this.$notification.error(error.response?.data || error))
      }

      return internalApiRequest({
        method: 'PATCH',
        path: `api/v2/contract/${this.contract?.id}/form/answers`,
        data: newTemplateData,
      }).catch((error) => this.$notification.error(error.response?.data || error))
    },
    approveContract() {
      this.isProcessingSignOrApprove = true

      if ('form' === this.contract?.type) {
        this.updateTemplateFieldsData()
          .then(() => {
            this.isProcessingSignOrApprove = false
            this.templateFieldsStore.resetTemplateFieldsStore()
          })
          .catch((error) => {
            this.isProcessingSignOrApprove = false
            this.$notification.error(getErrorResponseMessage(error.response?.data || error))
          })
      }

      approveContractAsRegisteredUser({
        contractId: this.contract.id,
        signIdentityId: this.signIdentity.id,
        note: this.approvalNote,
      })
        .then((response) => {
          this.processAcceptedOrFastSignedContract(response, WorkflowHelper.getNextUnsignedAfterApprove(response))
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.errors.accept'))
        })
        .finally(() => {
          this.loader = false
          this.loading = false
          this.showApproveDialog = false
        })
    },
    approveContractWithNote() {
      this.showApproveDialog = true
    },
    delegateSignature(upsertIdentity) {
      const user = upsertIdentity?.updatedIdentity ? upsertIdentity?.updatedIdentity : upsertIdentity

      const newSignee = {
        docsPerson: {
          email: user?.email,
          firstname: user?.firstname,
          lastname: user?.lastname,
          docsRole: this.signIdentity?.role || 'counterparty',

          mobile: user?.mobile || user?.mobile_tmp,

          identityForm: user?.type || 'nature',
          birthDate: user?.number || null,

          organizationName: user?.invoice_info?.invoice_organization_name || user?.organization_name,

          organizationPosition: user?.position || user?.organization_position,

          organizationStreet: user?.invoice_info?.invoice_street || user?.organization_street,

          organizationZip: user?.invoice_info?.invoice_zip || user?.organization_zip,

          organizationIco: user?.invoice_info?.invoice_ico || user?.organization_ico,

          organizationDic: user?.invoice_info?.invoice_dic || user?.organization_dic,
        },
        signatureFooter: user?.signatureFooter,
      }

      return internalApiRequest({
        method: 'PUT',
        path: `api/v2/contract/${this.contractId}/docsPerson/replace/${this.signIdentity.docs_person_id}`,
        data: newSignee,
      })
        .then((docsPerson) => {
          this.identityModal = false
          this.showEditSignatureDialog = false
          this.$notification.success(
            this.$t('contract.replace.success', {
              name: `${docsPerson.firstname} ${docsPerson.lastname}`,
            }),
          )

          location.reload()
        })
        .catch((error) => {
          this.identityModal = false
          this.showEditSignatureDialog = false
          this.$notification.error(getErrorResponseMessage(error.response.data))
        })
    },
    // moveDocument() {
    //   internalApiRequest({
    //     method: 'POST',
    //     path: `api/v2/contract/moveTo/workspace/${this.selectedWorkspace?.id}`,
    //     data: {
    //       contractIds: [this.contract?.id],
    //     },
    //   })
    //     .then(() => {
    //       this.$notification.success(this.$t('docs.move.completed'));
    //       this.showAvailableWorkspaces = false;
    //       this.$router.push({ name: 'dashboard' });
    //     })
    //     .catch(() => {
    //       this.$notification.error(this.$t('general.error'));
    //     });
    // },
    showAmlIdentificationCard() {
      this.showAmlContent =
        'draft' === this.contract.state && this.contract?.has_authentication && !this.$route.params?.innerChange
    },
    toggleCommentMode(mode) {
      this.commentsModalMode = mode
      this.canManageComments = !this.canManageComments
      this.$notification.success(this.$t(`document.comments.mode_${this.canManageComments ? 'active' : 'inactive'}`))
    },
    goToRoute(name, params = {}, query = {}) {
      this.$router.push({ name, params, query })
    },
    changeContract(contract) {
      this.showAmlContent = false

      if (parseInt(contract.id) === parseInt(this.contractId)) {
        return false
      }

      if (this.isCounterpartyView) {
        return this.$router.push({
          name: 'documentsDetailCounterparty',
          params: {
            workspace_id: this.workspaceId,
            contract_id: contract.id,
            ...this.redirectParameters,
          },
        })
      }

      if ('documentsDetail' === this.$route.name) {
        return this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.workspaceId,
            contract_id: contract.id,
            ...this.redirectParameters,
            innerChange: true,
          },
        })
      }

      return this.$router.push({
        name: 'createContent',
        params: {
          workspace_id: this.workspaceId,
          contract_id: contract.id,
          ...this.redirectParameters,
        },
      })
    },
    getEnvelopeName() {
      fetchEnvelopTitle(this.contractId).then((response) => (this.contractEnvelopeName = response.title))
    },
    changeEnvelopeName() {
      if (!this.contractEnvelopeName || !this.editEnvelopeName) {
        return (() => {
          this.editEnvelopeName = !this.editEnvelopeName
        })()
      }

      updateEnvelopTitle({
        id: this.contractId,
        title: this.contractEnvelopeName,
      })
        .then((resp) => {
          this.contractEnvelopeName = resp.title
        })
        .finally(() => {
          this.editEnvelopeName = !this.editEnvelopeName
        })
    },
    scrollToSigns() {
      if(this.showUploadedAttachment) {
        this.showUploadedAttachment = false
      }

      if ('approve' === this.sticker) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      } else if ('sign' === this.sticker) {
        let signPosition = 0

        if ('form' === this.contract.type) {
          const templateSignButtons = document.getElementsByClassName('contract-sign-button')
          signPosition = getSignatureVerticalPosition(templateSignButtons[0]) - window.innerHeight / 2
        } else if ('doc' === this.contract.type) {
          const pageHeight = document.getElementsByClassName('page')[0].clientHeight

          let firstSignPosition = this.myIdentity.positions[0]
          this.myIdentity.positions.forEach((sign) => {
            // eslint-disable-next-line no-mixed-operators
            if (
              null === firstSignPosition ||
              'undefined' === typeof firstSignPosition.offset_top ||
              (sign.page >= 0 && sign.page < firstSignPosition.page) ||
              sign.offset_top < firstSignPosition.offset_top
            ) {
              firstSignPosition = sign
            }
          })

          const signPositionOnPage = (firstSignPosition.offset_top / 100) * pageHeight
          signPosition = pageHeight * firstSignPosition.page + signPositionOnPage
        }

        window.scrollTo({
          top: signPosition,
          behavior: 'smooth',
        })
      }
    },
    setupSticker() {
      this.sticker = null

      if (!this.canViewTemplateSignatures) {
        return
      }

      const contractCurrentUser = this.contract?.current_user || null
      const contractCurrentSignIdentity = this.contractCurrentSignIdentity || null

      if (contractCurrentUser !== null && contractCurrentSignIdentity !== null) {
        if ('approve' === String(contractCurrentSignIdentity.contract_role)) {
          if (contractCurrentUser.can_accept && !contractCurrentSignIdentity.is_approved) {
            this.sticker = 'approve'
          }
        }

        if (hasSignIdentitySignAction(contractCurrentSignIdentity)) {
          if (!contractCurrentSignIdentity.is_signed) {
            this.sticker = 'sign'
          }
        }
      }
    },
    async loadSignIdentities(contractId = this.$route.params.contract_id, current_sign_identity) {
      try {
        if (current_sign_identity) {
          await this.fetchCurrentSignIdentity(contractId)
        }

        await this.fetchSignIdentities({
          contractId: contractId,
          routeName: this.$route.name,
        })
        this.signIdentitiesSuccessfullyLoaded = true
      } catch (e) {
        this.signIdentitiesSuccessfullyLoaded = false
        this.$notification.error(getErrorResponseMessage(e.response?.data || e))
      }
    },
    onUpdateContract(e) {
      if (e.changedState) {
        this.reloadSignatures = true
      }

      ContractService.detailWithSignsAndLinks(this.$route.params.contract_id, this.$route.params.workspace_id)
        .then(async (resp) => {
          let currentSI = this.contractCurrentSignIdentity
          let contractStateChanged = false

          await this.loadSignIdentities(resp.id, resp?.current_sign_identity)

          if (this.contractCurrentSignIdentity) {
            for (let signIdentity of this.contractSignIdentities) {
              if (
                `${signIdentity.id}` === `${currentSI.id}` &&
                (currentSI.is_approved !== signIdentity.is_approved ||
                  currentSI.is_signed !== signIdentity.is_signed ||
                  currentSI.is_signed_with_certificate !==
                    signIdentity.is_signed_with_certificate)
              ) {
                contractStateChanged = true;
              }
            }
          }

          if (contractStateChanged) {
            const unsignedAttachment = WorkflowHelper.getNextUnsignedAfterApprove(resp)

            if(unsignedAttachment) {
              return this.$router.replace({
                params: {
                  contract_id: unsignedAttachment.id,
                  },
                query: {
                  contract_id: unsignedAttachment.id,
                },
              });
            }

            if (this.isEnabledRedirectUserAfterSign && this.profile?.email !== this.contract?.creator?.email) {
              const redirectUrl = this.contract?.url_redirect

              if (redirectUrl) {
                return goToLinkWithTarget(redirectUrl, '_self')
              }
            }

            setTimeout(() => {
              window.location.reload()
            }, 2000)
          }

          if (e.changedState) {
            this.$store.commit('setContract', resp)
          }
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.not_loaded'))
          this.loaded = true
        })
        .finally(() => {
          this.reloadSignatures = false
        })
    },
    async fetchContractData() {
      this.loaded = false

      const contractId = this.$route.params.contract_id
      const workspaceId = this.$route.params.workspace_id

      ContractService.detailWithSignsAndLinks(contractId, workspaceId)
        .then(async (response) => {
          await this.loadSignIdentities(response.id, response?.current_sign_identity)

          if (response.one_device) {
            history.pushState(null, null, location.href)
            window.onpopstate = function () {
              history.go(1)
            }
          }

          if (this.canDownloadContractDocumentsCommentsReport) {
            this.contractCommentsStore
              .fetchContractComments([
                ...new Set([response?.id, ...response?.attachments?.map((attachment) => attachment?.id)]),
              ])
              .then(async () => {
                if ('true' === this.$route?.query?.downloadCommentsReport) {
                  await this.downloadDocumentsWithComments(true)
                }
              })
              .catch(() => {
                this.$notification.success(this.$t('document.cannot_be_downloaded'))
              })
          }

          const contractDate = response?.date || null
          const hasAuthentication = response?.has_authentication || false

          this.$store.commit(
            'setContract',
            Object.assign(response, {
              sign_identities: this.contractSignIdentities,
              current_sign_identity: this.contractCurrentSignIdentity,
            }),
          )

          const signatures = []
          const signingSignIdentities = response?.sign_identities?.filter((s) => hasSignIdentitySignAction(s))

          for (let signIdentity of signingSignIdentities) {
            if (signIdentity?.positions?.length > 0) {
              for (let position of signIdentity.positions) {
                signatures.push({ signIdentity, position })
              }
            } else {
              signatures.push({ signIdentity, position: null })
            }
          }

          await this.setSignatures(signatures)

          if (contractDate !== null) {
            this.created = parseDate(contractDate)
          }

          this.loaded = true

          this.setupSticker()

          if (hasAuthentication) {
            apiAuthorizedRequest('GET', `/api/v1/contracts/${contractId}/links`).then(
              (resp) => {
                this.links = resp.links
              },
              () => {
                this.links = []
              },
            )

            await this.fetchAuthState(contractId)
          } else {
            this.links = response.contract_flow?.shareLinks ? response.contract_flow.shareLinks : []
          }

          if (
            response.current_sign_identity?.one_device_group &&
            !response.sign_identities.some(
              (identity) =>
                identity?.one_device_group === response.current_sign_identity?.one_device_group &&
                !identity?.is_signed &&
                !identity?.is_approved &&
                'notice' !== identity?.contract_role,
            ) &&
            this.$route.query?.counterparty
          ) {
            const query = Object.assign({}, this.$route.query)
            delete query?.counterparty
            await this.$router.replace({ query })
          }

          if(this.allowDocsAttachments) {
            this.fetchAttachments()
          }
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.not_loaded'))
          this.loaded = true
        })
    },
    resendInvitation() {
      ContractService.resendInvitation(this.$route.params.contract_id).then(
        () => {
          this.$notification.success(this.$t('contract.resend.ok'))
        },
        () => {
          this.$notification.error(this.$t('contract.resend.failed'))
        },
      )
    },
    getPDFZip() {
      ContractService.openPDFZipAuthorized(this.$route.params.contract_id)
    },
    async downloadDocumentsWithComments() {
      this.overlay = true
      this.contractCommentsStore
        .createDocumentsCommentsReport()
        .then(() => {
          setTimeout(() => {
            this.$notification.success(this.$t('downloaded'))
          }, 1000)
        })
        .catch(() => {
          setTimeout(() => {
            this.$notification.success(this.$t('document.cannot_be_downloaded'))
          }, 1000)
        })
        .finally(() => {
          this.overlay = false
        })
    },
    downloadCheckList() {
      if (this.contract.state !== 'completed') {
        return goToLinkWithTarget(
          this.$router.resolve({
            name: 'contractDocumentRevisionList',
            params: {
              contractId: this.$route.params.contract_id,
            },
          }).href,
        )
      }

      this.loadingCheckList = true
      this.$notification.warning(this.$t('contract.checklist.preparing'))

      getContractCheckList({
        contractId: this.$route.params.contract_id,
        attempt: 5,
        timeout: 2500,
        next: (resp) => {
          axios({ url: resp.url, method: 'GET', responseType: 'blob' }).then((response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute('download', `KL_${this.contract.title}.pdf`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        },
        catchError: () => {
          this.$notification.error(this.$t('contract.checklist.error'))
        },
        routeName: this.$route.name,
      })
    },
    downloadAmlRevisionList() {
      this.$notification.warning(this.$t('contract.checklist.preparing'))
      getContractAmlRevisionList({
        contractId: this.$route.params.contract_id,
        next: (resp) => {
          axios({ url: resp.url, method: 'GET', responseType: 'blob' }).then((response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute('download', `identification-protocol-${this.contract.title}.pdf`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        },
        catchError: () => {
          this.$notification.error(this.$t('contract.checklist.error'))
        },
        routeName: this.$route.name,
      })
    },
    editHeaderModal() {
      this.isEditHeaderModalOpened = true
      this.areAvailablePartiesLoaded = false
      this.selectedUsers = []
      this.availableUsers = []

      ContractService.getRegisteredUsersForEditCounterparty(this.contract.id, this.myIdentity.id).then(
        (workspaceUsers) => {
          this.availableUsers = workspaceUsers.map((workspaceUser) => ({
            id: workspaceUser.user_id,
            fullname: workspaceUser.user_name,
            workspace_id: workspaceUser.workspace_id,
            workspace_name: workspaceUser.workspace_name,
          }))
          this.areAvailablePartiesLoaded = true
        },
        () => {
          this.$notification.error(this.$t('contract.edit_header.workspaces_error'))
          this.areAvailablePartiesLoaded = true
        },
      )
    },
    resetToDraft() {
      ContractService.redrawContractAfterSendIfNotDraft(this.contract, false)
        .then(() =>
          this.$router.push({
            name: 'createContent',
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
          }),
        )
        .catch(() => this.$notification.error(this.$t('contract.not_loaded')))
    },
    editContract() {
      const vm = this
      
      ContractService.redrawContractAfterSendIfNotDraft(this.contract, true)
        .then((response) => {
          if (response.has_removable_signatures) {
            vm.showRemoveSignatureDialog = true
          } else {
            vm.resetToDraft()
          }
        })
        .catch(() => this.$notification.error(this.$t('contract.not_loaded')))
    },
    changeHeader() {
      const selectedUser = this.selectedUsers[0]

      ContractService.editCounterpartyRegistered(this.contract.id, this.myIdentity.id, {
        workspace_id: selectedUser.workspace_id,
        user_id: selectedUser.id,
        isDryRun: true,
      }).then(
        (response) => {
          if (response.has_removable_signatures) {
            this.showRemoveHeaderSignatureDialog = true
          } else {
            this.editContractHeader()
          }
        },
        () => {
          this.$notification.error(this.$t('general.data_not_loaded'))
        },
      )
    },
    editContractHeader() {
      const selectedUser = this.selectedUsers[0]
      const request = {
        workspace_id: selectedUser.workspace_id,
        user_id: selectedUser.id,
      }

      ContractService.editCounterpartyRegistered(this.contract.id, this.myIdentity.id, request).then(
        (contract) => {
          this.loader = false
          this.isEditHeaderModalOpened = false
          this.$notification.success(this.$t('contract.edit_header.ok'))
          this.$store.commit('setContract', contract)
          if (this.$route.params.workspace_id !== request.workspace_id) {
            this.$router.push({
              name: 'documentsDetail',
              params: {
                workspace_id: request.workspace_id,
                contract_id: this.contract.id,
              },
            })
          }
        },
        () => {
          this.loader = false
          this.$notification.error(this.$t('contract.edit_header.failed'))
        },
      )
    },
    arrayBufferToBase64(buffer) {
      var binary = ''
      var bytes = [].slice.call(new Uint8Array(buffer))

      // eslint-disable-next-line no-return-assign
      bytes.forEach((b) => (binary += String.fromCharCode(b)))

      return window.btoa(binary)
    },
    deleteContract() {
      ContractService.delete(this.contract.id, true).then(
        () => {
          this.$notification.success(this.$t('docs.delete.ok'))
          this.$router.push({ name: 'home' })
        },
        () => {
          this.$notification.error(this.$t('docs.delete_draft.failed'))
        },
      )
    },
    deleteContractPdf() {
      ContractService.deleteCompleted(this.contract.id)
        .then((response) => {
          if ('ok' === response.status) {
            this.$notification.success(this.$t('docs.delete.ok'))
            return this.fetchContractData()
          }

          this.$notification.error(this.$t('docs.delete_draft.failed'))
        })
        .catch(() => {
          this.$notification.error(this.$t('docs.delete_draft.failed'))
        })
    },
    sign() {
      ContractService.detail(this.$route.params.contract_id, this.$route.params.workspace_id).then((resp) => {
        this.$store.commit('setContract', resp)

        this.loader = true

        if ('draft' === this.contract.state) {
          if (this.canSignDraft) {
            return this.$router.push({
              name: 'createContent',
              params: {
                workspace_id: this.$route.params.workspace_id,
                contract_id: this.$route.params.contract_id,
              },
            })
          }

          return this.$notification.error(this.$t('contract.errors.draft'))
        }

        if ('stamp' === this.signIdentity.contract_role) {
          return WorkflowHelper.clickOnStampLink({
            contract: this.contract,
            routes: {
              params: {
                workspace_id: this.$route.params.workspace_id,
                contract_id: this.$route.params.contract_id,
              },
              query: this.$route.query.progress ? { progress: 1 } : null,
            },
            signStamp: () =>
              approveContractAsRegisteredUser({
                contractId: this.contract.id,
                signIdentityId: this.signIdentity.id,
                note: null,
              }),
            redirectToCompleted: () =>
              this.$router.push({
                name: 'contractsCollectionCreated',
                params: {
                  contractId: this.$route.params.contract_id,
                  workspaceId: this.$route.params.workspace_id,
                },
              }),
          })
        }

        WorkflowHelper.clickOnSignLink({
          contract: this.contract,
          fastsign: (preloadedSignature) => {
            // copy-pasted from CreateSign.vue
            this.loaded = false
            ContractService.sendSignatureAuthorized(
              {
                signature_date: preloadedSignature.date,
                signature_place: preloadedSignature.place,
              },
              preloadedSignature.image,
              this.contract.id,
              this.contract.current_sign_identity.id,
              '',
            ).then(
              (response) => {
                this.loaded = true
                // TODO: i18n - displaying backend error message
                if (400 === response.code && 'Smlouva je již uzavřená' === response.title) {
                  return this.$notification.error(response.title)
                } else if (response.code >= 400) {
                  return this.$notification.error(response.title)
                }
                if ('completed' === response.state) {
                  window.dataLayer.push({
                    event: 'contract_proposal_sign_completed',
                  })
                }
                this.processAcceptedOrFastSignedContract(response, WorkflowHelper.getNextUnsignedAfterApprove(response))
              },
              () => {
                this.loaded = true
                this.$notification.error(this.$t('contract.errors.sign'))
              },
            )
          },
          routes: {
            params: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.$route.params.contract_id,
            },
          },
        })
      })
    },
    setViewedWithoutSign() {
      ContractService.setContractViewed(this.contract.id, this.signIdentity.id).then(
        (response) => {
          this.processAcceptedOrFastSignedContract(response, WorkflowHelper.getNextUnviewed(response))
        },
        () => {
          this.$notification.error(this.$t('contract.errors.accept'))
        },
      )
    },
    processAcceptedOrFastSignedContract(response, unApprovedContract) {
      const contract = response

      if (unApprovedContract) {
        this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: unApprovedContract.id,
          },
        })
        location.reload()
      } else {
        if (this.contract.one_device && contract.state !== 'completed') {
          this.fetchContractData()
        } else {
          this.$store.commit('setContract', contract)
          this.$router.push({
            name: 'contractsCollectionCreated',
            params: {
              contractId: this.contract.id,
              workspaceId: this.$route.params.workspace_id,
            },
          })
        }
      }
    },
    setSelectedUser(user) {
      this.selectedUsers = []
      this.selectedUsers.push(user)
    },
    removeUser(user) {
      // TODO - what with user
      console.log(user?.blabla)
      this.selectedUsers = []
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-link {
  &__description {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__number {
    color: #424242;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}

.options-menu {
  border: 1px solid #7031b4;
  border-radius: 4px;
  background-color: #ffffff;

  &__content--text {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__content--icon {
    height: 14.95px;
    width: 13px;
  }
}

.resend-links {
  padding: 15px 0;

  * {
    color: #000000;
    font-size: 14px;
    font-weight: initial;
  }
}

.menu-divider {
  height: 1px;
  border-color: #d2ddee;
}
</style>

<style lang="scss">
.contract-document-detail {
  &__wrapper {
    margin: -60px auto 0;
    max-width: 1350px;
    width: 100%;

    @include display(960px) {
      margin-top: -20px;
    }
  }

  &__content-view {
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
}

.v-list-item:hover {
  color: #5f23a0 !important;

  .v-icon {
    color: #5f23a0 !important;
  }
}

.contract-note {
  a {
    margin: 0 auto;
    font-weight: bold;
    text-decoration: underline;
    color: #6c35a8 !important;
  }
}

@import '~@/assets/sass/_contract-preview.scss';

body .contract-detail__content h1 {
  @include md {
    text-align: center;
  }
}

.available-user__box {
  background-color: #f2f6fc;
  border: 1px solid #d3deee;
  border-radius: 6px;
  padding: 43px 55px;
  margin-top: 29px;

  @include md {
    padding: 0 15px 15px 15px;
  }
}

.available-user__selected {
  margin: 0 auto;
  margin-top: 30px;
  width: 100%;
  outline: none;
  background: #ffffff;
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  height: 56px;
  padding: 5px;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;

  @include md {
    background-color: transparent;
  }

  &.single {
    border: none;
    padding: 0;
  }
}

.party-modal-header {
  padding: 0 57px;

  @include md {
    padding: 0;
  }
}

.available-user__selected__item {
  border: 1px solid #b1bbcb;
  border-radius: 6px;
  display: flow-root;
  height: 44px;
  float: left;
  padding: 5px 8px;
  margin-right: 5px;
  padding-right: 12px;
  background-color: #fff;
}

.available-user__ico {
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 4px;
}

.available-user__selected__name {
  float: left;
  padding: 0 8px;
}

.available-user__selected__close {
  float: left;
  height: 32px;
  transform: rotate(45deg);
}

.available-user__selected__close-icon {
  position: relative;
  top: 50%;
  color: white;
  background: #d4dff0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 100%;
  font-weight: 400;
  transform: translateY(-50%);
  display: block;
  cursor: pointer;
}

.available-user__selected__workspace-name {
  font-size: 10px;
  color: #616161;
  letter-spacing: -0.03px;
  text-align: left;
  font-weight: 300;
  text-decoration: none;
}

.available-user__selected__name-inner {
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;
  text-align: left;
  font-weight: 500;
  text-decoration: none;
}

.available-user__message {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  border-radius: 6px;
  background: #fffad1;
  font-size: 15px;
  color: #73560b;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  margin-top: 25px;
  position: relative;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
}

.available-users {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
}

.available-user {
  cursor: pointer;
  display: inline-block;
  margin-top: 15px;
  margin-right: 25px;
  @include clearfix;
}

.available-user__ico {
  float: left;
}

.available-user__data {
  padding-left: 30px;
  text-align: left;
}

.available-user__name {
  font-size: 16px;
  color: #5e239e;
  letter-spacing: -0.03px;
  text-align: left;
  font-weight: 500;
  text-decoration: none;
}

.available-user__workspace {
}

.available-user__name-inner {
  display: block;
  width: 100%;
}

.available-user__workspace-name {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}

.contract-detail__zoom-controll {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 9;

  @include md {
    display: none;
  }
}

.contract-meta {
  display: none;

  @include md {
    display: block;
    padding: 15px 15px 30px;
  }
}

.contract-detail con-part {
  padding: 0;
}

.contract-detail con-section {
  padding: 0;
  border: none;
  background: none;
  border-radius: 0;
  line-height: 1.4;
}

.contract-detail con-option {
  color: #000000;
  cursor: text;
}

.contract-detail con-options:before {
  display: none;
}

.contract-detail con-options {
  cursor: text;
}

.contract-detail__title {
  display: none;

  @include md {
    display: block;
  }
}

.workspace-users {
  margin-top: 45px;
}

.workspace-users__items {
  margin-top: 30px;
}

.workspace-user {
  padding: 20px 0;
  border: 1px solid #b1bbcb;
  max-width: 500px;
  font-size: 16px;
  margin: 20px auto;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  -webkit-transition: 0.2s;
  transition: 0.2s;

  &:hover {
    background-color: #f2f6fc;
  }
}

.show-workspace-contact {
  text-align: left;
  padding: 30px 0 0 0;
}

.contract-buttons {
  margin-top: 30px;

  @include md {
    display: none;
  }
}

.contract-buttons.show {
  @include md {
    display: block;
  }
}

.contract-detail h1 {
  font-weight: 500;
  margin-bottom: 10px;
  @include md {
    font-size: 25px;
    text-align: left;
  }
}

.contract-detail__content h2 {
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
  line-height: 23px;
  margin-top: 15px;
}

.contract-detail {
  max-width: 1380px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
  position: relative;
  @include md {
    display: block;
    display: flow-root;
  }
}

.contract-detail.show-full {
  max-width: 100%;
  width: 100%;
}

.contract-detail__content {
  width: 72%;
  float: left;
  padding: 0 15px;

  @include md {
    width: 100%;
    float: unset;
    padding: 0;
  }
}

.contract-detail.show-full .contract-detail__content {
  width: 100%;

  @include md {
    width: 100%;
  }
}

.contract-content-card {
  background: #ffffff;
  box-shadow: 0 12px 54px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 60px 90px;
  margin-left: auto;
  margin-right: auto;

  &.no-padding {
    padding: 0 0 45px;

    @include md {
      padding: 0 0 45px;
    }
  }

  @include md {
    padding: 50px 30px 0 30px;
    max-height: 390px;
    overflow: hidden;
    box-shadow: none;
  }
}

.contract-content-card.show {
  @include md {
    max-height: 100%;
  }
}

.contract-content-card__detail {
  &:before {
    @include md {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 200px;
      -webkit-box-shadow: inset 0 -140px 100px white;
      box-shadow: inset 0 -100px 100px white;
      z-index: 1;
      left: 0;
    }
  }
  @include md {
    max-height: 370px;
    position: relative;
    overflow: hidden;
  }
}

.contract-content-card.show .contract-content-card__detail {
  &:before {
    display: none;
  }

  @include md {
    max-height: 100%;
  }
}

.contract-detail__controls {
  padding: 40px 15px;
  width: 28%;
  float: left;

  @include md {
    float: unset;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 0 !important;
  }
}

.contract-detail.show-full .contract-detail__controls {
  display: none;
}

.contract-oponent__title {
  font-size: 11px;
  color: #8e97a4;
  letter-spacing: 1.28px;
  line-height: 18px;
  float: left;
  text-transform: uppercase;
  width: 100px;
  @include md {
    width: auto;
    padding-right: 5px;
  }
}
.contract-oponent__content {
  float: left;
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  letter-spacing: -0.03px;
  line-height: 17px;
  @include md {
    color: #8e97a4;
  }
}

.contract-oponent {
  padding: 2px 0;
  display: flow-root;
  @include clearfix;
}

.controls-title {
  margin-bottom: 15px;

  @include md {
    display: none;
  }
}

.contract-changes__ul {
  margin: 40px 0;
  @include md {
    margin: 25px 0;
  }
}

.contract-changes__ul__li {
  list-style-type: none;
  font-weight: normal;
  font-size: 17px;
  color: #000000;
  position: relative;

  .icon {
    position: absolute;
    right: 0;
    width: 20px;
    text-align: center;
    line-height: 22px;
    &:hover {
      transition: 0.2s;
    }
    @include md {
      display: none;
    }
  }

  a {
    padding: 15px 0;
    position: relative;
    border-bottom: solid 1px #e8edf5;
    width: 100%;
    display: block;
    text-align: left;
    text-decoration: none;
    &:hover {
      .icon:before {
        color: #5e239e;
        transition: 0.2s;
      }
    }
    @include md {
      border-bottom: none;
      text-align: center;
      color: #5e239e;
      padding: 5px 0;
    }
  }
}
.signature-detail {
  text-align: center;
}

.signature-detail__name {
  font-size: 18px;
  color: #000000;
  text-align: center;
}
.signature-detail__date {
  font-weight: 300;
  font-size: 15px;
  color: #4a4a4a;
  text-align: center;
}

.contract-signature__content {
  width: 50%;
  float: left;
  padding: 0 20px 20px;
  @include sm {
    width: 100%;
    margin-bottom: 15px;
  }
  .signature-img {
    margin-bottom: 0;
    height: 50px;
    @include md {
      height: 30px;
    }
  }
}

.contract-signature {
  width: 100%;
  display: inline-block;
  margin-top: 50px;
  @include md {
    z-index: 100;
  }
}

.signature-line {
  position: relative;
  height: 18px;
  margin-bottom: 10px;
  padding: 0 10px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.signature-line .icon {
  font-size: 18px;
  position: absolute;
  right: -18px;
}

.signature-line__path {
  padding: 0 10px;
  border-bottom: dashed 1px #b4c1d6;
  width: auto;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  right: 15px;
  left: 15px;
  max-width: 100%;
}

.signature-img {
  background-image: unset;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -16px;
}

.signature-img--empty {
  margin-bottom: 0 !important;
  height: 55px !important;

  button {
    width: 100% !important;
    max-width: 140px;
    margin: 0 auto;
    display: block;
  }
}

.contract-oponent-info {
  //  display: none;

  /*
  @include md{
      position: absolute;
      top: 40px;
      left: 30px;
  }
  */
}

.contract-buttons--more {
  display: none;

  @include md {
    display: block;
  }
}

.contract-open.contract-content-card {
  max-height: 100%;
}
.contract-open {
  .contract-content-card__detail {
    max-height: 100%;
    &:before {
      content: unset;
    }
  }
}

.selected-reason {
  border-top: none !important;
  .comment-topline__left-arrow {
    height: 1px;
    // background: rgba(255, 255, 255, 0.1);
    width: 2%;
    float: left;
    transform: rotate(-25deg);
    position: relative;
    bottom: 3px;
  }

  .comment-topline__right-arrow {
    height: 1px;
    // background: rgba(255, 255, 255, 0.1);
    width: 2%;
    float: left;
    transform: rotate(25deg);
    position: relative;
    bottom: 3px;
  }
  .comment-topline__left {
    height: 1px;
    // background: rgba(255, 255, 255, 0.1);
    float: left;
  }

  .comment-topline__right {
    height: 1px;
    // background: rgba(255, 255, 255, 0.1);
    float: left;
    position: relative;
  }
}
.other-reason {
  .comment-topline__left {
    width: 85.7%;
    transition: 0.5s;
    @include sm {
      width: 68%;
    }
  }
  .comment-topline__right {
    width: 9.3%;
    transition: 0.5s;
    @include sm {
      width: 28%;
    }
  }
}

.change-mind {
  .comment-topline__left {
    width: 60.5%;
    transition: 0.5s;
    @include sm {
      width: 28%;
    }
  }
  .comment-topline__right {
    width: 34.5%;
    transition: 0.5s;
    @include sm {
      width: 68%;
    }
  }
}

.not-for-me {
  .comment-topline__left {
    width: 35%;
    transition: 0.5s;
    @include sm {
      width: 68%;
    }
  }
  .comment-topline__right {
    width: 60%;
    transition: 0.5s;
    @include sm {
      width: 28%;
    }
  }
}

.dont-understand {
  .comment-topline__left {
    width: 9.3%;
    transition: 0.5s;
    @include sm {
      width: 28%;
    }
  }
  .comment-topline__right {
    width: 85.7%;
    transition: 0.5s;
    @include sm {
      width: 68%;
    }
  }
}

.comment-topline {
  position: absolute;
  top: -1px;
  width: 100%;
  border-radius: 6px;
  height: 20px;
  padding: 0 3px;
}

con-sign {
  width: 100%;
  display: table;
  clear: both;
  margin-top: 80px;
  margin-bottom: 80px;
  // TODO: why so big margin?
}
</style>
