<template>
  <v-menu v-model="show" offset-y z-index="1000000000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-fountain-pen-tip</v-icon>
      </v-btn>
    </template>

    <v-card width="30rem" class="overflow-y-hidden">
      <v-list>
        <v-list-item
          v-for="identity in signIdentities"
          :key="identity.id"
          class="py-1 mx-3 px-0 document-person-status__row"
        >
          <v-row align="center" justify="start">
            <v-col class="col-2">
              <SAvatar
                :size="40"
                :firstname="identity.firstname"
                :lastname="identity.lastname"
                :position="identity.variable_position"
                :profileImage="identity.avatar_url"
                :background-color="identity.color"
              />
            </v-col>

            <v-col class="col-7 col-sm-8 col-md-7 pl-0">
              <v-row align="center" justify="start" no-gutters>
                <v-col class="col-12 pb-0">
                  <div class="font-weight-medium">
                    {{ getIdentityFullName(identity) }}
                  </div>
                </v-col>

                <v-col class="col-12 pb-0">
                  <div class="font-weight-normal grey--text document-person-status__row--item">
                    <span :style="identity.is_signed || identity.is_approved ? 'color: #6cecb0' : ''">
                      {{ getContractRoleStatus(identity) }}
                    </span>
                    {{ getDate(identity) }}
                  </div>
                </v-col>

                <v-col v-if="isProposer" class="col-12 pb-0">
                  <div class="font-weight-normal grey--text document-person-status__row--item">
                    {{ identity.email }}
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="col-3 col-sm-2 col-md-3 pl-0 pb-0">
              <v-row align="center" justify="end" no-gutters>
                <v-col class="col-3 px-2" v-if="identity.email_sent_at">
                  <v-badge color="" overlap>
                    <template v-slot:badge>
                      <v-icon
                        v-if="identity.email_delivered_at"
                        color="#6cecb0"
                        style="border: 2px solid #6cecb0; border-radius: 50%"
                      >
                        mdi-check-bold
                      </v-icon>

                      <v-icon
                        v-else-if="identity.email_sent_at"
                        color="#DA2029"
                        style="border: 2px solid #da2029; border-radius: 50%"
                      >
                        mdi-close-thick
                      </v-icon>
                    </template>

                    <v-icon color="#B9C6D9">mdi-email-outline</v-icon>
                  </v-badge>
                </v-col>

                <v-col class="col-3 px-2">
                  <v-badge color="" overlap>
                    <template v-slot:badge>
                      <v-icon
                        v-if="identity.is_approved || identity.is_signed"
                        color="#6cecb0"
                        style="border: 2px solid #6cecb0; border-radius: 50%"
                      >
                        mdi-check-bold
                      </v-icon>

                      <v-icon
                        v-else-if="identity.rejected_at"
                        color="#DA2029"
                        style="border: 2px solid #da2029; border-radius: 50%"
                      >
                        mdi-close-thick
                      </v-icon>
                    </template>

                    <v-icon v-if="identity.contract_role === 'approve'" color="#B9C6D9">
                      mdi-file-check-outline
                    </v-icon>
                    <v-icon v-else-if="hasSignAction(identity)" color="#B9C6D9" style="zoom: 1.2">
                      mdi-fountain-pen-tip
                    </v-icon>
                  </v-badge>
                </v-col>

                <v-col class="col-3 px-2">
                  <v-badge color="" :value="identity.is_viewed || identity.is_contract_viewed" overlap>
                    <template v-slot:badge>
                      <v-icon color="#6cecb0" style="border: 2px solid #6cecb0; border-radius: 50%">
                        mdi-check-bold
                      </v-icon>
                    </template>

                    <v-icon color="#B9C6D9">mdi-eye-outline</v-icon>
                  </v-badge>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import { hasSignIdentitySignAction } from '@/features/contracts/stores/contractSignTypes'
import SAvatar from '@/common/components/SAvatar'

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export default {
  name: 'DocumentPersonsStatus',
  components: {
    SAvatar,
  },
  props: {
    signIdentities: {
      type: Array,
      default: () => {
        return []
      },
    },
    isProposer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    hasSignAction(identity) {
      return hasSignIdentitySignAction(identity)
    },
    getContractRoleStatus(identity) {
      if (identity.rejected_at) {
        return this.$t('contract.was_rejected')
      }

      if ('approve' === identity.contract_role) {
        return identity.is_approved ? this.$t('general.approved') : this.$t('contract.signs.role_approve')
      }

      if (hasSignIdentitySignAction(identity)) {
        return identity.is_signed ? this.$t('signing.signed') : this.$t('docs.pending.generic')
      }
    },
    getDate(identity) {
      if ('approve' === identity.contract_role && identity.approved_at) {
        return `${getFormattedDateByLocale({
          date: identity.approved_at,
          options: dateOptions,
        })}`
      }

      if (hasSignIdentitySignAction(identity) && identity.signed_at) {
        return `${getFormattedDateByLocale({
          date: identity.signed_at,
          options: dateOptions,
        })}`
      }

      return ''
    },
    getIdentityFullName(identity) {
      if (identity?.variable_position && !identity?.email) {
        return `${identity.variable_position}`
      }

      return `${identity.firstname} ${identity.lastname}`
    },
  },
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  max-width: 80%;
  width: auto;
  border: 1px solid #7031b4;
  background-color: #ffffff !important;
}

.document-person-status {
  &__row {
    border-bottom: 1px solid #d4dfef;

    :deep(&--item) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }
  }

  &__row:last-child {
    border-bottom: unset;
  }
}
</style>
