import download from 'downloadjs'
import fontkit from '@pdf-lib/fontkit'
import { PageSizes, PDFDocument, rgb } from 'pdf-lib'
import { ContractService } from '@/services/ContractService'
import { authorizedRequest } from '@/services/ApiService'
import { getFormattedDate, getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import i18n from '@/app/plugins/i18n'

export const DEFAULT_FONT_SIZE = 12
export const DEFAULT_LINE_HEIGHT = 16
export const TYPES = {
  title: {
    indentation: 50,
    color: rgb(95 / 255, 35 / 255, 160 / 255),
  },
  subtitle: {
    indentation: 30,
    color: rgb(95 / 255, 35 / 255, 160 / 255),
  },
  reply: {
    indentation: 50,
    color: rgb(0, 0, 0),
  },
}

export const DocumentWithComments = function ({
  contractId,
  title,
  signIdentities,
  locale = i18n.locale,
  onlyReturnFile = false,
}) {
  this.customFont = {}
  this.documentInfo = null
  this.documentComments = []
  this.pdfDocument = null
  this.pdfDocumentLink = ''
  this.title = title
  this.locale = locale

  this.generate = async function () {
    this.documentInfo = await authorizedRequest({
      method: 'GET',
      endpoint: `/api/v2/contracts/${contractId}/document/converted`,
    })
    this.pdfDocumentLink = this.documentInfo.file_url
    this.documentComments = await ContractService.getComments(contractId, 'comments')

    return fetch(this.pdfDocumentLink).then(async (resp) => {
      const documentBuffer = await resp.arrayBuffer()

      this.pdfDocument = await PDFDocument.load(documentBuffer, {
        updateMetadata: false,
        renderInteractiveForms: false,
        ignoreEncryption: true,
      })

      this.pdfDocument.setTitle(`${title} - comments`)
      this.pdfDocument.registerFontkit(fontkit)
      this.customFont = {
        regular: await this.pdfDocument.embedFont(await fetchFont('/fonts/blogger-sans/Blogger_Sans.otf')),
        bold: await this.pdfDocument.embedFont(await fetchFont('/fonts/blogger-sans/Blogger_Sans-Bold.otf')),
      }

      await this.addCommentsPointsToDocument()
      await this.renderSignatures(signIdentities)
      await this.addCommentsToNextPages()

      if (onlyReturnFile) {
        return {
          fileName: this.documentInfo?.file_name,
          file: await this.pdfDocument.save(),
        }
      } else {
        await this.downloadDocument(onlyReturnFile)
      }
    })
  }

  this.addCommentsPointsToDocument = async function () {
    this.pdfDocument = await createCommentsPoints({
      pdfDocument: this.pdfDocument,
      comments: this.documentComments,
      customFont: this.customFont,
    })
  }

  this.renderSignatures = async function (signIdentities) {
    for (let signIdentity of signIdentities) {
      if (signIdentity.is_signed && !!signIdentity?.signature_image) {
        for (let position of signIdentity.positions) {
          const signatureImage = signIdentity?.signature_image
          if (signatureImage) {
            const currentPage = this.pdfDocument.getPage(position.page)
            const { width, height } = currentPage.getSize()
            const arrayBuffer = await fetch(signatureImage).then((res) => res.arrayBuffer())

            const signImage = await this.pdfDocument.embedPng(arrayBuffer)
            const { positionX, positionY, signatureWidth, signatureHeight } = getSignaturePosition({
              position,
              signImage,
              pWidth: width,
              pHeight: height,
            })

            currentPage.drawImage(signImage, {
              x: positionX,
              y: positionY,
              width: signatureWidth,
              height: signatureHeight,
            })

            const dateHeader = getSignatureHeader({
              signIdentity,
              locale: this.locale,
            })
            currentPage.drawText(`${dateHeader}`, {
              x:
                positionX +
                this.customFont.bold.widthOfTextAtSize(dateHeader, 8) -
                this.customFont.bold.widthOfTextAtSize(dateHeader, 8) / 2,
              y: positionY + signatureHeight + 4,
              size: 8,
              font: this.customFont.bold,
              color: rgb(0, 0, 0),
            })
          }
        }
      }
    }
  }

  this.addCommentsToNextPages = async function () {
    this.pdfDocument = await createPageWithComments({
      pdfDocument: this.pdfDocument,
      comments: getCommentsByLines({
        comments: this.documentComments,
        customFont: this.customFont,
        width: PageSizes.A4[0],
      }),
      customFont: this.customFont,
    })
  }

  this.downloadDocument = async function () {
    download(await this.pdfDocument.save(), `${this.title} - comments.pdf`, 'application/pdf')
  }
}

export const fetchFont = (path) => {
  return fetch(path).then((res) => {
    return res.arrayBuffer()
  })
}

export const createCommentsPoints = ({ pdfDocument, comments, customFont }) => {
  const pages = pdfDocument.getPages()
  const { width, height } = pdfDocument.getPage(0).getSize()
  const numberOfPages = pages?.length || 1
  const blockHeight = numberOfPages * height

  for (let comment of comments) {
    const x = (width * comment?.anchor?.offset_left) / 100
    const y = (blockHeight * comment?.anchor?.offset_top) / 100
    const pageIndex = Math.floor(y / height)
    let currentPage = pdfDocument.getPage(pageIndex)

    currentPage = addCommentPoint({
      page: currentPage,
      customFont,
      position: { x, y: (pageIndex + 1) * height - y - 10 },
      title: `${comment?.anchor?.title}`,
      text: `${comment?.content?.slice(0, 100)}`,
    })
  }

  return pdfDocument
}

export const addCommentPoint = ({ page, customFont, position, title, text }) => {
  const fontSize = 8

  page.drawCircle({
    x: position.x + 10,
    y: position.y - 4,
    size: 10,
    borderWidth: 0,
    color: rgb(95 / 255, 35 / 255, 160 / 255),
    opacity: 1,
    borderOpacity: 1,
  })
  page.drawText(title, {
    x: position.x - customFont.bold.widthOfTextAtSize(title, fontSize) / 2 + 10,
    y: position.y - 7,
    size: fontSize,
    font: customFont.bold,
    color: rgb(1, 1, 1),
  })

  const preview = createCommentPointPreview({
    font: customFont.bold,
    text,
  })

  for (let previewLine of preview.previewLines) {
    let maxHeightPosition = Math.floor(page.getSize()?.height / 4)

    page.drawText(previewLine.text, {
      x: position.x + (position.x > page.getSize()?.width / 2 ? -1 * preview.maxWidth - 15 : 40),
      y:
        position.y -
        15 +
        (position.y < maxHeightPosition
          ? -1 * (DEFAULT_LINE_HEIGHT * previewLine.line) + preview.linesHeight
          : -1 * (DEFAULT_LINE_HEIGHT * previewLine.line - DEFAULT_LINE_HEIGHT)),
      size: DEFAULT_FONT_SIZE,
      font: customFont.bold,
      color: rgb(95 / 255, 35 / 255, 160 / 255),
    })
  }

  return page
}

export const createCommentPointPreview = ({ text, font }) => {
  let preview = {
    previewLines: [],
    maxWidth: 0,
    linesHeight: 0,
  }

  let numberOfLine = 1
  for (let line of fillPageLines({ text, font, maxWidth: 200 })) {
    const lineWidth = font.widthOfTextAtSize(line, DEFAULT_FONT_SIZE)

    preview.previewLines.push({
      line: numberOfLine++,
      text: line,
    })

    Object.assign(preview, {
      maxWidth: lineWidth > preview.maxWidth ? lineWidth : preview.maxWidth,
      linesHeight: preview.linesHeight + DEFAULT_LINE_HEIGHT,
    })
  }

  font.widthOfTextAtSize(preview.previewLines[0]?.text, DEFAULT_FONT_SIZE)

  return preview
}

export const createPageWithComments = ({ pdfDocument, comments, customFont }) => {
  const page = pdfDocument.addPage(PageSizes.A4)
  let pageHeight = PageSizes.A4[1] - DEFAULT_LINE_HEIGHT * 2

  while (pageHeight > DEFAULT_LINE_HEIGHT && comments?.length > 0) {
    const line = comments.shift()

    if ('object' === typeof line && Object.keys(line)?.length > 0) {
      page.drawText(line.content, {
        x: line.type.indentation,
        y: pageHeight,
        size: DEFAULT_FONT_SIZE,
        font: customFont[line.font],
        color: line.type.color,
      })
    }

    pageHeight -= DEFAULT_LINE_HEIGHT
  }

  if (comments.length > 0) {
    pdfDocument = createPageWithComments({
      pdfDocument,
      comments,
      customFont,
    })
  }

  return pdfDocument
}

export const getCommentsByLines = ({ comments, customFont, width }) => {
  let transformedComments = []

  for (let comment of comments) {
    for (let line of fillPageLines({
      text: `${comment?.anchor?.title} - ${comment?.author} (${getFormattedDate({
        date: comment?.created_at,
      })})`,
      font: customFont.bold,
      fontSize: DEFAULT_FONT_SIZE,
      maxWidth: width - TYPES.title.indentation,
    })) {
      transformedComments.push({
        type: TYPES.title,
        font: 'bold',
        content: line,
      })
    }

    for (let line of fillPageLines({
      text: `${comment?.content}`,
      font: customFont.regular,
      fontSize: DEFAULT_FONT_SIZE,
      maxWidth: width - TYPES.subtitle.indentation,
    })) {
      transformedComments.push({
        type: TYPES.subtitle,
        font: 'regular',
        content: line,
      })
    }

    transformedComments.push({})

    for (let commentReply of comment?.replies) {
      for (let line of fillPageLines({
        text: `${commentReply?.author} (${getFormattedDate({
          date: commentReply?.created_at,
        })})`,
        font: customFont.bold,
        fontSize: DEFAULT_FONT_SIZE,
        maxWidth: width - TYPES.reply.indentation,
      })) {
        transformedComments.push({
          type: TYPES.reply,
          font: 'bold',
          content: line,
        })
      }

      for (let line of fillPageLines({
        text: `${commentReply?.content}`,
        font: customFont.regular,
        fontSize: DEFAULT_FONT_SIZE,
        maxWidth: width - TYPES.reply.indentation,
      })) {
        transformedComments.push({
          type: TYPES.reply,
          font: 'regular',
          content: line,
        })
      }

      transformedComments.push({})
    }

    transformedComments.push({})
  }

  return transformedComments
}

export const getSignatureHeader = ({ signIdentity, locale }) => {
  return i18n.t('signature.header.place_day', locale, {
    place: signIdentity?.signature_place,
    day: getFormattedDateByLocale({
      date: signIdentity?.signature_date,
      locale,
    }),
  })
}

export const getSignaturePosition = ({ position, signImage, pWidth, pHeight }) => {
  const pageRatio = pWidth / pHeight
  const imageRation = signImage.width / (signImage.height * 0.8)
  const relativeHeight = (position.relative_width * 0.54989126) / pageRatio

  const signatureWidth = pWidth * (position.relative_width / 100)
  const signatureHeight = (pHeight * (relativeHeight / 100)) / imageRation
  const positionX = pWidth * (position.offset_left / 100)
  const positionY = pHeight - pHeight * (position.offset_top / 100) - signatureHeight - signatureHeight * 0.2
  return {
    position,
    positionX,
    positionY,
    signatureWidth,
    signatureHeight,
  }
}

export const fillPageLines = ({ text, font, fontSize = DEFAULT_FONT_SIZE, maxWidth }) => {
  const paragraphs = text.split('\n')

  for (let index = 0; index < paragraphs.length; index++) {
    let paragraph = paragraphs[index]

    if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth - 30) {
      const words = paragraph.split(' ')
      const newParagraph = []
      let i = 0

      newParagraph[i] = []

      for (let k = 0; k < words.length; k++) {
        const word = words[k]
        newParagraph[i].push(word)
        if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth - 30) {
          newParagraph[i].splice(-1)
          i = i + 1
          newParagraph[i] = []
          newParagraph[i].push(word)
        }
      }
      paragraphs[index] = newParagraph.map((p) => p.join(' ')).join('\n')
    }
  }

  return paragraphs.join('\n').split('\n')
}
