<template>
  <v-card elevation="0">
    <div v-if="isLoading" style="min-height: 20rem">
      <Loader />
    </div>

    <template v-else-if="isInProgress">
      {{ $t('aml.auth.not_filled') }}
    </template>

    <template v-else-if="isRejectedByProposer">
      {{ $t('aml.auth.denied') }}
    </template>

    <template v-else>
      <v-row v-if="hasLivelinessVerification" class="pb-10">
        <v-col cols="12" class="title font-weight-bold pb-0">
          {{ $t('aml.fill.liveness') }}
        </v-col>

        <v-col cols="12" class="py-0">
          <v-row align="center" justify="start">
            <v-col cols="auto" class="pr-0">
              <v-icon :color="livenessCheckResult.color" large>
                {{ livenessCheckResult.icon }}
              </v-icon>
            </v-col>
            <v-col>{{ livenessCheckResult.message }}</v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="hasPoliticalVerification" class="pb-10">
        <v-col cols="12" class="title font-weight-bold pb-0">
          {{ $t('aml.fill.questionnaire') }}
        </v-col>

        <v-col cols="12" class="py-0">
          <v-row align="center" justify="start">
            <template v-if="authPoliticalFilled || isCanceledByProposer">
              <v-col class="col-12 col-md-auto">
                {{ $t('aml.political.selected_choice') }}
              </v-col>

              <v-col class="col-12 col-md-auto">
                {{ authPoliticalMessage }}
              </v-col>
            </template>

            <template v-else>
              <v-col class="col-12 col-md-auto">
                {{ $t('contract.external_company.not_found1') }}
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="hasDocumentVerification" class="pb-10">
        <v-col cols="12" class="title font-weight-bold pb-0">
          {{ $t('aml.uploaded.images') }}
        </v-col>

        <v-col cols="12">
          <template v-if="authDocumentFilled || isCanceledByProposer">
            <v-row align="center" justify="start">
              <v-col v-for="image in authDocumentImages" :key="image.id" class="col-6 col-md-2 text-center">
                <ImageView
                  :auth-person-id="authData.docs_person_id"
                  :document-id="authData.auths.document.id"
                  :document-image="image"
                />
              </v-col>
            </v-row>
          </template>

          <template v-else>
            {{ $t('contract.external_company.not_found1') }}
          </template>
        </v-col>
      </v-row>

      <v-row v-if="hasMicroPaymentVerification" class="pb-10">
        <v-col cols="12" class="title font-weight-bold pb-0">
          {{ $t('aml.micropayment') }}
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col class="col-12 col-md-6">
              <v-row class="px-3" align="center">
                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('contract.reject.note') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ authMicroPaymentNote }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="title font-weight-bold pb-md-0">
          <v-row align="center" justify="start">
            <v-col class="col-12 col-md-auto pb-0 pb-md-3">
              {{ $t('bank_account.info') }}
            </v-col>
            <v-col v-if="!isPaymentCompleted" class="col-12 col-md-auto pb-3 pb-md-3">
              <v-btn
                color="primary"
                :loading="isReloadingPaymentStatus"
                :block="$vuetify.breakpoint.mobile"
                outlined
                small
                @click="reloadPaymentStatus"
              >
                <v-icon class="pr-1" small>mdi-refresh</v-icon>
                {{ $t('payment.check_status') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-expand-transition>
          <v-col v-if="canShowPaymentStatusMessage" cols="12" class="pb-0">
            <v-alert class="px-3 py-1" type="warning" outlined dense>
              {{ paymentStatusMessage }}
            </v-alert>
          </v-col>
        </v-expand-transition>

        <v-col cols="12">
          <v-row>
            <v-col class="col-12 col-md-6">
              <v-row class="px-3" align="center">
                <v-col cols="12" class="py-1 pl-0 font-weight-bold"> {{ $t('aml.received_information') }}: </v-col>
                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('general.name_and_surname') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ paymentFullName }}
                </v-col>

                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('workspace.bank_account.account_number') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ paymentBankAccount }}
                </v-col>

                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('payment.status') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ paymentStatus }}
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-row class="px-3" align="center">
                <v-col cols="12" class="mt-5 mt-md-0 py-1 pl-0 font-weight-bold">
                  {{ $t('aml.required_information') }}:
                </v-col>
                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('general.name_and_surname') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ `${requiredFirstname} ${requiredLastname}` }}
                </v-col>

                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('general.phone_number') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ requiredMobile }}
                </v-col>

                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('general.email_address') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ requiredEmail }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="hasSmsVerification" class="pb-10">
        <v-col cols="12" class="title font-weight-bold pb-0">
          {{ $t('verification.sms') }}
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col class="col-12">
              <v-row class="px-3" align="center">
                <v-col class="col-6 col-md-5 py-1 pl-0 text-left font-weight-medium">
                  {{ $t('general.phone_number') }}:
                </v-col>
                <v-col cols="6" class="py-1 pl-0 text-left">
                  {{ smsPhoneNumberVerified }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card-actions v-if="isApprovedByProposer" class="pt-0 px-0">
        <v-row align="center" justify="center">
          <v-col cols="12" class="col-12 text-center">
            <v-btn
              color="error"
              min-width="18rem"
              :loading="cancelingVerification"
              :block="$vuetify.breakpoint.mobile"
              outlined
              x-large
              @click="cancelContactVerification"
            >
              {{ $t('verification.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-card-actions
        v-else-if="!isApprovedByProposer && !isRejectedByProposer && !isCanceledByProposer"
        class="pt-10 px-0"
      >
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-auto">
            <v-btn
              color="primary"
              x-large
              min-width="14rem"
              :disabled="!isFilledByCounterparty"
              :loading="submitted"
              :block="$vuetify.breakpoint.mobile"
              @click="submit"
            >
              {{ $t('general.approve') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-auto">
            <v-dialog v-model="customerReturn.returnDialog" max-width="600px" scrollable persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  outlined
                  x-large
                  min-width="14rem"
                  :disabled="isInProgress"
                  :block="$vuetify.breakpoint.mobile"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('general.return_to_customer') }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t('general.bug_report') }}</span>
                </v-card-title>

                <v-card-text class="pb-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          :label="$t('contract.signers')"
                          :value="`${authData.contact.firstname} ${authData.contact.lastname}`"
                          hide-details
                          outlined
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="pb-0">
                        <span class="subtitle-2">
                          {{ $t('general.about') }}
                        </span>
                      </v-col>

                      <v-col cols="12" class="pt-0">
                        <v-checkbox
                          name="customer-return-liveness"
                          v-model="customerReturn.liveness"
                          :label="$t('aml.fill.liveness')"
                          hide-details
                        ></v-checkbox>

                        <v-checkbox
                          v-if="hasMicroPaymentVerification"
                          name="customer-return-political"
                          v-model="customerReturn.political"
                          :label="$t('aml.questionnaire')"
                          hide-details
                        ></v-checkbox>

                        <v-checkbox
                          name="customer-return-document"
                          v-model="customerReturn.document"
                          :label="$t('aml.identity')"
                          hide-details
                        ></v-checkbox>

                        <v-checkbox
                          v-if="hasMicroPaymentVerification"
                          name="customer-return-microTransaction"
                          v-model="customerReturn.microTransaction"
                          :label="$t('aml.microtransaction2')"
                          hide-details
                        ></v-checkbox>

                        <v-checkbox
                          v-if="hasSmsVerification"
                          name="customer-return-microTransaction"
                          v-model="customerReturn.sms"
                          :label="$t('verification.sms')"
                          hide-details
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          name="customer-return-message"
                          v-model="customerReturn.message"
                          :label="$t('general.yours_message')"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" x-large text @click="customerReturn.returnDialog = false">
                    {{ $t('general.cancel') }}
                  </v-btn>
                  <v-btn color="primary" x-large text @click="returnToCustomer">
                    {{ $t('general.send') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { apiAuthorizedRequest } from '@/services/ApiService'
import { cancelVerification } from '@contacts/services/verificationService'
import { getDocumentsImagesState } from '@contract/services/VerificationService'
import { getPaymentInfo } from '@contract/services/openPayUPaymentsService'
import Loader from '@/components/Loader'
import ImageView from '@contract/components/ImageView'

export default {
  name: 'AmlContentCard',
  components: {
    Loader,
    ImageView,
  },
  data() {
    return {
      loaded: false,
      authImages: [],
      submitted: false,
      cancelingVerification: false,
      paymentStatusReloading: false,
      customerReturn: {
        returnDialog: false,
        political: false,
        document: false,
        microTransaction: false,
        liveness: false,
        message: '',
      },
      rejected: false,
    }
  },
  props: {
    authData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return !this.loaded
    },
    requiredFirstname() {
      return this.authData?.contact?.firstname
    },
    requiredLastname() {
      return this.authData?.contact?.lastname
    },
    requiredEmail() {
      return this.authData?.contact?.email
    },
    requiredMobile() {
      return this.authData?.contact?.mobile
    },
    hasPoliticalVerification() {
      return 'undefined' !== typeof this.authData?.auths?.political
    },
    hasLivelinessVerification() {
      return 'undefined' !== typeof this.authData?.liveness_check && null !== this.authData?.liveness_check
    },
    hasSmsVerification() {
      return 'undefined' !== typeof this.authData?.auths?.sms
    },
    hasDocumentVerification() {
      return 'undefined' !== typeof this.authData?.auths?.document
    },
    hasMicroPaymentVerification() {
      return 'undefined' !== typeof this.authData?.auths?.microTransaction
    },
    isReloadingPaymentStatus() {
      return this.paymentStatusReloading
    },
    canShowPaymentStatusMessage() {
      return !(
        !this.isLoading &&
        'COMPLETED' === this.authData?.auths?.microTransaction?.pay_u_order?.status &&
        this.authData?.auths?.microTransaction?.pay_u_order?.full_name
      )
    },
    paymentStatusMessage() {
      if ('COMPLETED' === this.authData?.auths?.microTransaction?.pay_u_order?.status) {
        return this.$t('verification.microtransaction.missing_bank_account')
      }

      if ('CANCELED' === this.authData?.auths?.microTransaction?.pay_u_order?.status) {
        return this.$t('verification.microtransaction.canceled')
      }

      return this.$t('verification.microtransaction.waiting_for_payment')
    },
    authDocumentImages() {
      return this.authImages
    },
    authPoliticalMessage() {
      return this.authData.auths?.political?.is_political
        ? this.$t('aml.political.selected_political')
        : this.$t('aml.political.selected_not_political')
    },
    authMicroPaymentNote() {
      return this.authData.auths?.microTransaction?.note
    },
    authPoliticalApproved() {
      return !this.hasPoliticalVerification || 'ok' === this.authData.auths?.political?.status
    },
    authPoliticalRejected() {
      return 'rejected' === this.authData.auths?.document?.status
    },
    authPoliticalFilled() {
      return 'submitted' === this.authData.auths?.political?.status || 'ok' === this.authData.auths?.political?.status
    },
    authDocumentApproved() {
      return !this.hasDocumentVerification || 'ok' === this.authData.auths?.document?.status
    },
    authDocumentRejected() {
      return 'rejected' === this.authData.auths?.document?.status
    },
    authDocumentFilled() {
      return 'submitted' === this.authData.auths?.document?.status || 'ok' === this.authData.auths?.document?.status
    },
    authMicroPaymentApproved() {
      return !this.hasMicroPaymentVerification || 'ok' === this.authData.auths?.microTransaction?.status
    },
    authMicroPaymentRejected() {
      return 'rejected' === this.authData.auths?.microTransaction?.status
    },
    authMicroPaymentFilled() {
      return (
        'submitted' === this.authData.auths?.microTransaction?.status ||
        'ok' === this.authData.auths?.microTransaction?.status
      )
    },
    authSmsApproved() {
      return !this.hasSmsVerification || 'ok' === this.authData.auths?.sms?.status
    },
    authSmsRejected() {
      return 'rejected' === this.authData.auths?.sms?.status
    },
    authSmsFilled() {
      return (
        !this.hasSmsVerification ||
        'submitted' === this.authData.auths?.sms?.status ||
        'ok' === this.authData.auths?.sms?.status
      )
    },
    isFilledByCounterparty() {
      return (
        (this.authPoliticalFilled || !this.hasPoliticalVerification) &&
        (this.authDocumentFilled || !this.hasDocumentVerification) &&
        (this.authMicroPaymentFilled || !this.hasMicroPaymentVerification) &&
        (this.authSmsFilled || !this.hasSmsVerification)
      )
    },
    isInProgress() {
      const auths = [
        this.authData.auths?.political?.status,
        this.authData.auths?.document?.status,
        this.authData.auths?.microTransaction?.status,
        this.authData.auths?.sms?.status,
      ]

      const isSomeRejected =
        auths.every((elem) => ['rejected', 'submitted', undefined].includes(elem)) &&
        auths.some((elem) => 'rejected' === elem) &&
        auths.some((elem) => 'submitted' === elem)

      const isInProcess =
        ('in_process' === this.authData.auths?.political?.status || !this.hasPoliticalVerification) &&
        ('in_process' === this.authData.auths?.document?.status || !this.hasDocumentVerification) &&
        ('in_process' === this.authData.auths?.microTransaction?.status || !this.hasMicroPaymentVerification) &&
        ('in_process' === this.authData.auths?.sms?.status || !this.hasSmsVerification)

      return isSomeRejected || isInProcess
    },
    isApprovedByProposer() {
      return (
        (this.authPoliticalApproved || !this.hasPoliticalVerification) &&
        (this.authDocumentApproved || !this.hasDocumentVerification) &&
        (this.authMicroPaymentApproved || !this.hasMicroPaymentVerification) &&
        (this.authSmsApproved || !this.hasSmsVerification)
      )
    },
    isRejectedByProposer() {
      return (
        (this.authPoliticalRejected || !this.hasPoliticalVerification) &&
        (this.authDocumentRejected || !this.hasDocumentVerification) &&
        (this.authMicroPaymentRejected || !this.hasMicroPaymentVerification) &&
        (this.authSmsRejected || !this.hasSmsVerification)
      )
    },
    isCanceledByProposer() {
      return Object.keys(this.authData.auths).every((auth) => {
        return 'expired' === this.authData.auths[auth].status || 'ok' === this.authData.auths[auth].status
      })
    },
    livenessCheckResult() {
      const livenessCheckState = this.authData?.liveness_check?.state || null

      if ('HIGH' === livenessCheckState) {
        return {
          icon: 'mdi-emoticon-outline',
          message: this.$t('aml.liveness_check.high'),
          color: 'success',
        }
      }

      if ('MED' === livenessCheckState) {
        return {
          icon: 'mdi-emoticon-neutral-outline',
          message: this.$t('aml.liveness_check.med'),
          color: 'warning',
        }
      }

      return {
        icon: 'mdi-emoticon-sad-outline',
        message: this.$t('aml.liveness_check.low'),
        color: 'error',
      }
    },
    paymentBankAccount() {
      return this.authData?.auths?.microTransaction?.pay_u_order?.bank_account || this.$t('general.not_specified')
    },
    paymentFullName() {
      return this.authData?.auths?.microTransaction?.pay_u_order?.full_name || this.$t('general.not_specified')
    },
    isPaymentCompleted() {
      const paymentStatus = this.authData?.auths?.microTransaction?.pay_u_order?.status || null

      return 'COMPLETED' === paymentStatus
    },
    paymentStatus() {
      const paymentStatus = this.authData?.auths?.microTransaction?.pay_u_order?.status || null

      if ('COMPLETED' === paymentStatus) {
        return this.$t('payment.completed')
      }

      if ('PENDING' === paymentStatus) {
        return this.$t('payment.pending')
      }

      if ('CANCELED' === paymentStatus) {
        return this.$t('payment.canceled')
      }

      return this.$t('general.not_specified')
    },
    smsPhoneNumberVerified() {
      return this.authData?.auths?.sms?.mobile || ''
    },
  },
  created() {
    const docsPersonId = this.authData.docs_person_id
    const authDocumentId = this.authData.auths?.document?.id

    this.loaded = false

    if (authDocumentId) {
      getDocumentsImagesState(docsPersonId, authDocumentId).then((resp) => {
        this.authImages = resp?.auth_document_images || []
        this.loaded = true
      })
    } else {
      this.loaded = true
    }
  },
  methods: {
    ...mapActions({
      fetchAuthState: 'verification/fetchAuthState',
    }),
    getLargeImage(image) {
      return image.file_url
    },
    reloadPaymentStatus() {
      this.paymentStatusReloading = true
      const microTransactionId = this.authData?.auths?.microTransaction?.id || null
      const orderId = this.authData?.auths?.microTransaction?.pay_u_order?.order_id || null

      if (null === microTransactionId || null === orderId) {
        return (() => {
          setTimeout(() => {
            this.paymentStatusReloading = false
            this.$notification.error(this.$t('payment.check_status.error'))
          }, 700)
        })()
      }

      getPaymentInfo({
        authId: this.authData.docs_person_id,
        microTransactionId,
        orderId,
      })
        .then((response) => {
          this.authData.auths.microTransaction.pay_u_order = response
          this.paymentStatusReloading = false
        })
        .catch(() => {
          this.$notification.error(this.$t('payment.check_status.error'))
          this.paymentStatusReloading = false
        })
    },
    async submit() {
      this.submitted = true

      const docsPersonId = this.authData.docs_person_id

      if (this.hasPoliticalVerification) {
        await apiAuthorizedRequest(
          'PATCH',
          `/api/v1/auths/${docsPersonId}/politicals/${this.authData.auths.political.id}/approve`,
        )
      }

      if (this.hasDocumentVerification) {
        await apiAuthorizedRequest(
          'PATCH',
          `/api/v1/auths/${docsPersonId}/documents/${this.authData.auths.document.id}/approve`,
        )
      }

      if (this.hasMicroPaymentVerification) {
        await apiAuthorizedRequest(
          'PATCH',
          `/api/v1/auths/${docsPersonId}/micro_transactions/${this.authData.auths.microTransaction.id}/approve`,
        )
      }

      if (this.hasSmsVerification) {
        await apiAuthorizedRequest('PATCH', `/api/v1/auths/${docsPersonId}/sms/${this.authData.auths.sms.id}/approve`)
      }

      this.fetchAuthState(this.$route.params.contract_id).then(() => {
        this.$notification.success(this.$t('aml.auth.approved'))
        this.submitted = false
      })

      window.location.reload()
    },
    returnToCustomer() {
      this.rejected = true

      const contractId = this.$route.params.contract_id
      const docsPersonId = this.authData.docs_person_id
      const types = []

      if (this.customerReturn.political) {
        types.push('political')
      }

      if (this.customerReturn.document) {
        types.push('document')
      }

      if (this.customerReturn.microTransaction) {
        types.push('microTransaction')
      }

      if (this.customerReturn.sms) {
        types.push('sms')
      }

      apiAuthorizedRequest('POST', `/api/v1/auths/contracts/${contractId}/person/${docsPersonId}/reject`, {
        types,
        message: this.customerReturn.message,
        removeLivenessCheck: this.customerReturn.liveness,
      })
        .then(() => {
          this.customerReturn.returnDialog = false
          this.fetchAuthState(this.$route.params.contract_id).then(() => {
            this.$notification.success(this.$t('aml.auth.denied'))
            this.rejected = false
          })
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'))
        })
    },
    cancelContactVerification() {
      this.cancelingVerification = true

      const types = ['bankId']
      const verificationsTypes = {
        document: this.hasDocumentVerification,
        microTransaction: this.hasMicroPaymentVerification,
        political: this.hasPoliticalVerification,
        sms: this.hasSmsVerification,
      }

      for (let verificationType in verificationsTypes) {
        if (verificationsTypes[verificationType]) {
          types.push(verificationType)
        }
      }

      cancelVerification({
        contactId: this.authData.contact_id,
        verificationTypes: { types },
      })
        .then(() => {
          this.fetchAuthState(this.$route.params.contract_id).then(() => {
            this.$notification.success(this.$t('verification.cancel.success'))
          })
        })
        .catch(() => {
          this.$notification.error(this.$t('verification.cancel.error'))
        })
        .finally(() => {
          this.cancelingVerification = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
