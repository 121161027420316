<template>
  <v-container
    class="ma-0 pa-0 file-upload-box__wrapper"
    :class="{ 'file-upload-box__wrapper--dragging': dragging }"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
  >
    <div v-if="hasUploadedFiles" class="file-upload-box">
      <div class="uploaded-files">
        <span class="uploaded-files__title">{{ $t('general.uploaded') }}</span>
      </div>

      <drop-list :items="uploadedFiles" @reorder="$event.apply(uploadedFiles)">
        <template v-slot:item="{ item }">
          <drag :key="item.id" :data="item" style="width: 100%">
            <AttachmentsUploadedItem
              :upload-file="item"
              @delete-uploaded-file="deleteDocumentDialogMethod(true, item)"
              @upload-again="
                $refs.filesInput.update(item, {
                  active: true,
                  error: '',
                  progress: '0.00',
                })
              "
            />
          </drag>
        </template>
      </drop-list>
    </div>

    <div v-else class="file-upload-box">
      <v-img class="mb-6" :src="uploadDocumentSrc" max-height="122.96px" max-width="137px" contain></v-img>

      <span class="file-upload-box__title mb-6">{{ $t('upload.drop_zone') }}</span>

      <span class="file-upload-box__separator mb-6">{{ $t('general.or') }}</span>

      <label for="document" class="file-upload-box__upload-label d-flex align-center justify-center">
        <v-icon color="white" small left>mdi-tray-arrow-up</v-icon>

        <span class="upload-label__text">
          {{ $t('contract.choose_file') }}
        </span>
      </label>
    </div>

    <v-row v-if="hasUploadedFiles" class="mt-6 mx-auto flex-column" style="width: 100%">
      <v-col>
        <span class="uploaded-files-note__title">{{ $t('review.comments.add_comment').toUpperCase() }}</span>
        <v-text-field
          v-model="attachmentNote"
          outlined
          class="mt-2" 
          hide-details
        ></v-text-field>
      </v-col>

      <v-col class="text-center">
        <v-btn
          v-if="canContinue"
          color="primary"
          :loading="uploadingFiles"
          class="file-upload-box__upload-label"
          @click="uploadFiles"
        >
          {{ $t('general.done') }}
        </v-btn>
      </v-col>
    </v-row>

    <file-upload
      ref="filesInput"
      v-model="uploadedFiles"
      name="document"
      :drop="true"
    ></file-upload>

    <SConfirmDialog
      v-model="deleteDocumentDialogObject.show"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="removeUploadedFile(deleteDocumentDialogObject.file)"
    />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import { Drag, DropList } from 'vue-easy-dnd'
import { useContractStore } from '@/features/contracts/stores/contract'
import pathParse from 'path-parse'
import FileUpload from 'vue-upload-component'
import SConfirmDialog from '@/common/components/SConfirmDialog'
import AttachmentsUploadedItem from '@/features/contract/components/AttachmentsUploadedItem'
import { useContractSettingsStore } from '@/features/contracts/stores/contractSettings'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import { internalApiRequest } from '@/shared/services/apiService';
import { generateAuthHeader } from '@/config/authHeader'


export default defineComponent({
  name: 'AttachmentsUploader',
  components: {
    AttachmentsUploadedItem,
    Drag,
    DropList,
    FileUpload,
    SConfirmDialog,
  },
  props: {
    docsId: {
      type: Number,
      required: true,
    },
    docsPersonId: {
      type: Number,
      required: true,
    }
  },
  setup() {
    const contractStore = useContractStore()
    const contractSettingsStore = useContractSettingsStore()

    return {
      contractStore,
      contractSettingsStore,
    }
  },
  data() {
    return {
      uploadedFiles: [],
      uploadingFiles: false,
      dragging: false,
      deleteDocumentDialogObject: {
        show: false,
        file: {},
      },
      attachmentNote: '',
    }
  },
  computed: {
    canContinue() {
      return this.uploadedFiles?.length > 0;
    },
    uploadDocumentSrc() {
      return require('@/features/contracts/assets/undraw_add_files_re_v09g.svg')
    },
    hasUploadedFiles() {
      return 0 < this.uploadedFiles?.length
    },
  },
  watch: {
    uploadedFiles: {
      deep: true,
      handler: function () {
        setTimeout(() => {
          this.dragging = false
        }, 500)
      },
    },
  },
  methods: {
    checkFileExtension(fileName) {
      const fileExtension = pathParse(fileName)?.ext || ''

      return !['.pdf', '.docx', '.xlsx', '.odt', '.odf', '.jpg', '.jpeg', '.png']?.includes(fileExtension.toLowerCase())
    },
    deleteDocumentDialogMethod(show, file) {
      this.deleteDocumentDialogObject = Object.assign({}, { show, file })
    },
    removeUploadedFile(selectedFile) {
      this.uploadedFiles = this.uploadedFiles.filter((f) => f !== selectedFile)
    },
    uploadFiles() {
      this.uploadingFiles = true
      
      const formData = new FormData();

      formData.append('docs_person_id', this.docsPersonId);
      formData.append('title', this.uploadedFiles[0].name);
      formData.append('file', this.uploadedFiles[0].file);
      formData.append('note', this.attachmentNote);

      internalApiRequest({
        method: 'POST',
        path: `api/v1/docs/${this.docsId}/attachment`,
        data: formData,
        headers: generateAuthHeader({
          authorization: 'Bearer',
          isForm: true,
        }),
      })
        .then(() => {
          this.$notification.success(this.$t('docs.add_attachment_success'));
          this.uploadedFiles = []
          this.attachmentNote = ''
          this.$emit('close-dialog')
          this.$emit('reload-attachments')
        })
        .catch((err) => {
          this.$notification.error(getErrorResponseMessage(err.data));
        })
        .finally(() => {
          this.uploadingFiles = false
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.file-upload-box {
  display: flex;
  max-width: 736px;
  min-height: 361px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #b4c1d6;
  border-radius: 4px;

  &__wrapper {
    box-sizing: border-box;
    max-width: 736px;
    min-height: 361px;
    background-color: #ffffff;
    margin: 0 auto;

    &--dragging {
      border: 2px solid var(--v-primary-base);
    }
  }

  &__title {
    color: #424242;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27.5px;
    text-align: center;
  }

  &__separator {
    color: #424242;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17.5px;
  }

  &__upload-label {
    height: 40px;
    padding: 0 1rem;
    border-radius: 4px;
    background: linear-gradient(90deg, #6226a3 0%, #934fdd 100%);
    color: white;
    cursor: pointer;

    .upload-label__text {
      height: 17px;
      width: 103px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 17px;
    }
  }

  &__upload-next {
    height: 17px;
    color: #424242;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    cursor: pointer;
  }
}

.uploaded-files {
  max-width: 475px;
  width: 100%;

  &__title {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }
}

.uploaded-files__actions {
  max-width: 475px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.uploaded-files-note {
  max-width: 475px;
  width: 100%;

  &__title {
    color: #868e96;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.5px;
  }
}

.credits-cost__info {
  color: #868e96;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17.5px;
}
</style>