<template>
  <v-card elevation="0" width="100%" min-height="50vh">
    <v-card-title>
      <h2 class="headline font-weight-medium mb-0 mx-auto">
        {{ $t('document.deleted_by_client.title') }}
      </h2>
    </v-card-title>
    <v-card-text>
      <p class="subtitle-1 font-weight-medium text-center">
        {{ $t('document.deleted_by_client.message') }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DeletedDocumentView',
}
</script>

<style lang="scss" scoped></style>
