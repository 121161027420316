<template>
  <v-col cols="12">
    <v-row align="start">
      <v-col class="col-12 col-md-auto py-1 pl-0 pr-5 text-left font-weight-medium"> {{ description }}: </v-col>

      <v-col class="py-1 pl-5 text-left">
        <v-row class="my-0 py-1" align="center" justify="start" no-gutters>
          <v-col class="col-md-auto text-left font-weight-medium"> {{ $t('general.id_number') }}: </v-col>
          <v-col class="pl-2 text-left">
            {{ number }}
          </v-col>
        </v-row>

        <v-row class="my-0 py-1" align="start" justify="start" no-gutters>
          <v-col class="col-md-auto text-left font-weight-medium"> {{ $t('general.document_issuer') }}: </v-col>
          <v-col class="pl-2 text-left">
            {{ `${issuer}` }}
            <template v-if="issuerDate">
              {{
                `${$t('signature.header.on').charAt(0).toLocaleLowerCase()}${$t('signature.header.on').slice(
                  1,
                )} ${issuerDate}`
              }}
            </template>
          </v-col>
        </v-row>

        <v-row class="my-0 py-1" align="center" justify="start" no-gutters>
          <v-col cols="auto" class="text-left font-weight-medium"> {{ $t('general.valid_until') }}: </v-col>
          <v-col class="pl-2 text-left">
            {{ validTo }}
          </v-col>
        </v-row>

        <v-row class="my-0 py-1" align="center" justify="start" no-gutters>
          <v-col cols="auto" class="text-left font-weight-medium"> {{ $t('general.country') }}: </v-col>
          <v-col class="pl-2 text-left">
            {{ country }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BankIdContentCardDocumentItem',
  props: {
    bankIdDocument: {
      type: Object,
      required: true,
    },
    idAuth: {
      type: [String, Number],
      required: true,
    },
    idAuthBankId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    description() {
      return this.bankIdDocument?.description || this.$t('general.not_specified')
    },
    number() {
      return this.bankIdDocument?.number || this.$t('general.not_specified')
    },
    issuer() {
      return this.bankIdDocument?.issuer || this.$t('general.not_specified')
    },
    issuerDate() {
      return null !== this.bankIdDocument?.issuer_date ? this.formatDate(this.bankIdDocument?.issuer_date) : ''
    },
    validTo() {
      return null !== this.bankIdDocument?.valid_to ? this.formatDate(this.bankIdDocument?.valid_to) : false
    },
    country() {
      return this.bankIdDocument?.country || this.$t('general.not_specified')
    },
  },
  methods: {
    formatDate(date) {
      return moment(String(date)).format('DD.MM.YYYY') || this.$t('general.not_specified')
    },
  },
}
</script>

<style scoped></style>
