import { render, staticRenderFns } from "./SignByOtherPreview.vue?vue&type=template&id=6bfbd694&scoped=true"
import script from "./SignByOtherPreview.vue?vue&type=script&lang=js"
export * from "./SignByOtherPreview.vue?vue&type=script&lang=js"
import style0 from "./SignByOtherPreview.vue?vue&type=style&index=0&id=6bfbd694&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bfbd694",
  null
  
)

export default component.exports