<template>
  <div class="dashboard-state" :class="`dashboard-state__width-${width}`">
    <div :class="`dashboard-state-item__color-${currentState.color}`">
      {{ currentState.title }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/app/plugins/i18n'
import { authorizedApiRequest } from '@/services/ApiService'

export default {
  name: 'ContractState',
  props: {
    stateType: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: 'fit',
    },
  },
  data() {
    return {
      circularFetcher: null,
      documentState: null,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
    }),
    states() {
      return (
        {
          draft: {
            color: 'text',
            title: i18n.t('docs.drafts'),
          },
          review: {
            color: 'yellow',
            title: i18n.t('review.pending_generic'),
          },
          'review.me': {
            color: 'red',
            title: i18n.t('review.pending_me'),
          },
          pending: {
            color: 'yellow',
            title: i18n.t('docs.pending.generic'),
          },
          'pending.approve': {
            color: 'yellow',
            title: i18n.t('docs.pending.approve'),
          },
          'pending.me': {
            color: 'red',
            title: i18n.t('docs.pending.me'),
          },
          'pending.expiring': {
            color: 'purple',
            title: i18n.t('docs.pending.expiring'),
          },
          rejected: {
            endingState: true,
            color: 'text',
            title: i18n.t('docs.rejected'),
          },
          expired: {
            endingState: true,
            color: 'text',
            title: i18n.t('docs.expired'),
          },
          completed: {
            endingState: true,
            color: 'green',
            title: i18n.t('docs.completed'),
          },
          'completed.expiring': {
            endingState: true,
            color: 'purple',
            title: i18n.t('docs.completed.expiring'),
          },
          sealing: {
            color: 'yellow',
            title: i18n.t('docs.processing'),
          },
          'done.approve': {
            color: 'green',
            title: i18n.t('general.approved'),
          },
          'done.sign': {
            color: 'green',
            title: i18n.t('signing.signed'),
          },
          'archive.trashed': {
            color: 'darkred',
            title: i18n.t('docs.archive.trash'),
          },
        } || this.states.draft
      )
    },
    currentState() {
      return this.states[this.documentState]
    },
  },
  async created() {
    this.documentState = this.stateType
    await this.checkContractState()
  },
  mounted() {
    this.activateAutoFetchingAuths()
  },
  beforeDestroy() {
    clearInterval(this.circularFetcher)
  },
  methods: {
    activateAutoFetchingAuths() {
      const self = this
      this.circularFetcher = setInterval(() => {
        self.checkContractState()
      }, 1000 * 5)
    },
    checkContractState() {
      authorizedApiRequest({
        method: 'GET',
        endpoint: `api/v2/contract/${this.contract?.main_contract_id || this.contract?.id}/state/global`,
      }).then((resp) => {
        const state = resp?.state

        // if (this.documentState !== state) {
        this.$emit('update', { changedState: this.documentState !== state })
        // }

        if (this.states?.[state]?.endingState) {
          clearInterval(this.circularFetcher)
        }

        this.documentState = state || this.stateType
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-state__width {
  &-fit {
    width: fit-content;
  }
  &-full {
    width: 100%;
  }
}

.dashboard-state {
  padding-bottom: 4px;
  font-size: small;

  .dashboard-state-item__root {
    padding: 6px;
    border-radius: 6px;
  }

  .dashboard-state-item__color-text {
    @extend .dashboard-state-item__root;
    background-color: #e6e6e6;
    color: #0d0d0d;
  }
  .dashboard-state-item__color-yellow {
    @extend .dashboard-state-item__root;
    background-color: #fdefb5;
    color: #9e772e;
  }
  .dashboard-state-item__color-red {
    @extend .dashboard-state-item__root;
    background-color: #fde2e3;
    color: #d21717;
  }
  .dashboard-state-item__color-purple {
    @extend .dashboard-state-item__root;
    background-color: #f4eaff;
    color: #6329a2;
  }
  .dashboard-state-item__color-green {
    @extend .dashboard-state-item__root;
    background-color: #6decaf;
    color: #0f522f;
  }
}
</style>
